import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  margin: 0.5rem 1rem;
  padding: 0.5rem 1rem 1rem 1rem;
  cursor: pointer;
  min-width: 4.5rem;

  @media (max-width: 760px) {
    margin: 0.5rem 0rem;
    padding: 0.5rem 0.5rem;
    min-width: 4rem;
  }

  div {
    display: grid;
    place-items: center;
  }
`;

function Holder({ children, onClick }) {
  return (
    <Wrapper onClick={onClick}>
      <div>{children}</div>
    </Wrapper>
  );
}

export default Holder;
