import React, { useState, useEffect, useRef } from "react";

const PinInput = ({ getPin, header }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const [focusedIndex, setFocusedIndex] = useState(null);

  const handleInputChange = (index, value) => {
    if (isNaN(value) || value === "") {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;

        if (index > 0 && value === "") {
          inputRefs[index - 1].current.focus();
        }

        return newOtp;
      });
    } else {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;

        if (index < newOtp.length - 1 && value !== "") {
          inputRefs[index + 1].current.focus();
        }

        return newOtp;
      });
    }
  };

  const handleBackspace = (index) => {
    if (index > 0 || (index === 0 && otp[index] !== "")) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = "";
        if (index > 0) {
          inputRefs[index - 1].current.focus();
        }
        return newOtp;
      });
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault(); // Prevent default behavior of the Backspace key
      handleBackspace(index);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").split("");
    if (pastedData.length <= 6) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        pastedData.forEach((value, index) => {
          if (!isNaN(value)) {
            newOtp[index] = value;
          }
        });
        return newOtp;
      });
    }
  };

  useEffect(() => {
    const completedOtp = otp.join("");
    if (completedOtp.length === 6) {
      getPin(completedOtp);
    }
  }, [otp, getPin]);

  return (
    <div>
      <h2 style={{ marginTop: 0 }}>{header}</h2>
      <div style={styles.otpInputcontainer}>
        {otp.map((digit, index) => (
          <input
            key={index}
            ref={inputRefs[index]}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => {
              handleInputChange(index, e.target.value);
            }}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={handlePaste}
            style={{
              ...styles.otpInputField,
              ...(index === focusedIndex && styles.otpInputFieldFocus),
            }}
            onFocus={() => setFocusedIndex(index)}
            onBlur={() => setFocusedIndex(null)}
          />
        ))}
      </div>
    </div>
  );
};

const styles = {
  otpInputcontainer: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  otpInputField: {
    width: "24px",
    height: "40px",
    textAlign: "center",
    fontSize: "16px",
    border: "2px solid #ccc",
    borderRadius: "5px",
    marginRight: "4px",
    outline: "none",
    transition: "border-color 0.3s ease-in-out",
  },

  otpInputFieldFocus: {
    border: "2px solid #f80033",
  },
};

export default PinInput;
