import React, { useState } from "react";
import styled from "styled-components";
import Spacer from "../../../../components/spacer/Spacer";
import { AppColors } from "../../../../config/AppColors";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { HiOutlineClipboardDocument } from "react-icons/hi2";
import { toast } from "react-toast";
import PinkButton from "../../../../components/buttons/PinkButton";
import AppLoader from "../../../../components/loader/AppLoader";
import CustomButton from "../../../../components/buttons/CustomButton";
import axios from "axios";
import { getToken } from "../../../../utils";
import api from "../../../../api/api";

const Wrapper = styled.div`
  position: relative;
  .copy {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    color: ${AppColors.WHITE};
  }

  .pink {
    stroke: ${AppColors.Pinkish};
  }

  .cents {
    display: flex;
    justify-content: center;
  }
`;

const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  margin: 1rem 0;
  color: ${AppColors.WHITE};
  p {
    margin: 0;
  }

  .center {
    color: ${AppColors.WHITE};
    font-size: 0.85rem;
    font-weight: 200;
    margin: 1rem auto;
  }

  .trns {
    display: inline-block;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

function LeagueDetails({ details, getCurrent, refresh }) {
  const [code, setCode] = useState(details?.code);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const archive = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setLoading(true);
    const res = await api.getById("tornament", details?.id, "archive");
    if (res.status) {
      console.log("start", res);
      setLoading(false);
      toast.success(res.message);
      refresh();
    } else {
      setLoading(false);
      console.log("start err", res);
    }
  };

  const del = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setDeleting(true);
    const res = await api.getById("tornament", details?.id, "delete");
    if (res.status) {
      console.log("start", res);
      setDeleting(false);
      toast.success(res.message);
      refresh();
    } else {
      setDeleting(false);
      console.log("start err", res);
    }
  };

  return (
    <Wrapper>
      <Spacer height="1rem" />
      {details?.code ? (
        <>
          <Flex>
            <p>League name</p>
            <p>{details?.name}</p>
          </Flex>
          <Flex>
            <p>Number of teams allowed</p>
            <p>{details?.number_of_teams}</p>
          </Flex>
          <Flex>
            <p>League starts</p>
            <p>{details?.start_date.slice(0, 10)}</p>
          </Flex>
          <Flex>
            <p>League ends</p>
            <p>{details?.end_date.slice(0, 10)}</p>
          </Flex>
          <CopyToClipboard
            text={details?.code}
            onCopy={() => {
              setCopied(true);
              toast.success("Copied");
            }}
          >
            <p className="copy">
              {" "}
              <span>Copy League Code to invite other Players</span>
              <HiOutlineClipboardDocument className={copied ? "pink" : ""} />
            </p>
          </CopyToClipboard>
          <Flex>
            <PinkButton
              isloading={loading}
              onClick={(e) => {
                archive();
              }}
            >
              Archive <span className="trns">League</span>
            </PinkButton>
            <CustomButton
              isloading={deleting}
              onClick={(e) => {
                del();
              }}
              bg="#fff"
              color="#f80033"
            >
              Delete <span className="trns">League</span>
            </CustomButton>
          </Flex>
        </>
      ) : (
        <div>
          <Flex>
            <h3 className="center">You are yet to create a league</h3>
          </Flex>
          <div className="cents">
            <PinkButton
              onClick={(e) => {
                getCurrent(2);
              }}
            >
              Create League
            </PinkButton>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

export default LeagueDetails;
