import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BsChevronRight } from "react-icons/bs";
import { AppColors } from "../../config/AppColors";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: hsl(224deg 29.41% 10% / 55%);
  h4 {
    color: ${AppColors.WHITE};
    font-size: 1rem;
    padding: 0 2.5rem;
  }
`;
const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 0 2.5rem;

  p {
    color: ${AppColors.WHITE};
    background-color: ${AppColors.Pinkish};
    padding: 1rem;
    border-radius: 0.8rem;
    cursor: pointer;
    &:hover {
      background: rgb(160 159 159 / 20%);
    }
  }
`;

const Header = styled.p`
  position: relative;
  background: rgb(193 193 193 / 15%);
  color: ${AppColors.WHITE};
  font-size: 1.2rem;
  margin: 0;
  padding: 1rem 0;
  text-align: center;

  .back {
    position: absolute;
    left: 5%;
    cursor: pointer;
  }
`;

function Delete({ getOption, getHit }) {
  return (
    <Wrapper>
      <Header>
        {" "}
        <HiOutlineArrowNarrowLeft
          className="back"
          onClick={() => {
            getHit(false);
            getOption("");
          }}
        />{" "}
        Are you sure you want to delete your account?
      </Header>{" "}
      <Flex>
        <p>Yes</p>
        <p>No</p>
      </Flex>
    </Wrapper>
  );
}

export default Delete;
