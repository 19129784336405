import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  user: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userSaved: {
      reducer(state, action) {
        state.user = action.payload;
      },
      prepare(content) {
        return {
          payload: {
            content,
          },
        };
      },
    },
    clearUser: {
      reducer(state) {
        state.user = {};
      },
    },
  },
});

export const selectUser = (state) => state.user.user.content;

export const { userSaved, clearUser } = userSlice.actions;

export default userSlice.reducer;
