import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Logo from "../components/Logo/Logo";
import Spacer from "../components/spacer/Spacer";
import { AppColors } from "../config/AppColors";
import Selectors from "../components/selectors/Selectors";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

const Wrapper = styled.div`
  position: relative;
  padding: 0rem;
  width: -webkit-fill-available;

  @media (min-width: 768px) {
    display: grid;
    place-items: center;
    padding: 2rem 2rem 0 2rem;
  }
  h2 {
    color: white;
  }

  .holding {
    height: 40vh;
    position: relative;
    display: grid;
    place-items: center;
    div {
      margin: 0 auto;
    }
  }

  .footing {
    max-width: 1000px;
    overflow-x: hidden;
    min-width: 380px;

    @media (max-width: 768px) {
      padding: 1vh 0;
    }
  }

  .b-holding {
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 4%;
    left: 50%;
    transform: translate(-50%, 50%);
    background: #1c2127;
    width: 100%;

    @media (max-width: 768px) {
      bottom: 5%;
    }
  }
`;

const AppWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
`;

const NoticeWrapper = styled.div`
  position: relative;

  .center-img {
    display: flex;
    justify-content: center;
  }

  .mini {
    object-fit: cover;
    width: 40%;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }
  .text-holder {
    position: relative;
    max-height: 40vh;
    overflow-y: auto;
  }

  .back {
    position: absolute;
    left: 1rem;
    color: #fff;
    cursor: pointer;
  }

  h2,
  h3,
  h4,
  p,
  ul,
  ol {
    color: ${AppColors.WHITE};
  }

  .how {
    margin-top: 2rem;
  }

  ol img {
    object-fit: cover;
    width: 90%;
    border-radius: 1rem;
  }

  li {
    margin: 0.3rem 0;
    line-height: 1.4rem;
  }

  h3 {
    font-size: 1.3rem !important;
    font-weight: 500;
    text-align: center;
  }
  h4 {
    font-size: 1.1rem;
    text-align: center;
    font-weight: 400;
  }
  p {
    font-weight: 100;
    line-height: 1.4rem;
    @media (max-width: 768px) {
      font-weight: 100;
    }
  }
`;

const Curved = styled.div`
  position: relative;
  display: flex;
  background: hsl(224deg 29.41% 10% / 55%);
  justify-content: center;
  padding: 1rem 1.5rem 2rem 1.5rem;
  max-width: 550px;

  @media (max-width: 768px) {
    margin: 0 0.5rem;
    max-width: unset;
  }

  .hold {
    width: 100%;
    @media (min-width: 768px) {
      margin-top: 0rem;
    }
  }

  hr {
    color: ${AppColors.DarkGrey};
  }
`;

function HowToUse() {
  const navigate = useNavigate();
  const token = localStorage.getItem("PRivalsToken");

  return (
    <Wrapper>
      <div className="inner-wrapper">
        <Logo />
        <Spacer height="0.2rem" />
        <Curved>
          <div className="hold">
            <NoticeWrapper>
              <HiOutlineArrowNarrowLeft
                className="back"
                onClick={() => {
                  navigate(-1);
                }}
              />
              <h3>Play Rivalz – How to use</h3>
              <div className="text-holder">
                <ol>
                  <li>
                    <p>Log in or sign up on the homescreen</p>
                    <img src="/images/loginPR.png" alt="login_image" />
                  </li>
                  <li>
                    <p>
                      You will then be prompted to log in using the details that
                      you signed up with.
                    </p>
                    <img src="/images/loginPR2.png" alt="login_image" />
                  </li>
                  <li>
                    <p>
                      Welcome to the Play Rivalz dashboard! Here you can create
                      a league or tournament and/or see your progress in current
                      leagues/tournaments. The results from your leagues and
                      tournaments will also be here for you to see.
                    </p>
                    <img src="/images/htu-home.png" alt="login_image" />
                  </li>
                </ol>
                <h3 className="how">How to create a league or tournament</h3>
                <p>
                  Creating a league or tournament is simple, we want you to get
                  up an running as quickly as possible and start tracking
                  results with your peers! For the purpose of this example, we
                  will be creating a league.
                </p>
                <ol>
                  <li>
                    <p>
                      Choose which you want to create and then hit the red
                      button ‘join or create league’
                    </p>
                    <img src="/images/htu-league.png" alt="login_image" />
                  </li>
                  <li>
                    <p>
                      On the next page you will see your leagues page. If you
                      are already part of leagues they will populate here, if
                      not then there will be no leagues. If there is leagues,
                      click on the far right tab ‘create league’. If there are
                      no leagues you will see the red button create league or
                      you can click on the far right tab ‘create league’ to get
                      started.
                    </p>
                    <img src="/images/htu-l-details.png" alt="login_image" />
                  </li>
                  <li>
                    <p>
                      On the next page, you will have to name your league, set
                      the number of players (min 4 and max 20) and determine if
                      it is an open league (anyone can join) or private (code
                      only). Click on league rules to move to the next step
                    </p>
                    <img src="/images/htu-c-league.png" alt="login_image" />
                  </li>
                  <li>
                    <p>
                      On the league rules page, you can determine the laws of
                      your league! You can select the nation, league and as
                      league creator, you get to choose your team first. Most of
                      our players do an offline draft to see who has what team
                      OR you can choose who you want!
                    </p>
                    <p>
                      Choose half length and if you want home and away fixtures
                      (each team plays each other twice). Click COMPLETE when
                      done.
                    </p>
                    <p>
                      Please note – due to this being a beta release, not all
                      nations are available (we have Premier League, La Liga,
                      Ligue 1 and Bundesliga). Also, some dropdown boxes may
                      need a few seconds for options to appear.
                    </p>
                    <img src="/images/htu-league-rules.png" alt="login_image" />
                  </li>
                  <li>
                    <p>
                      You will then be taken back to the ‘create league’ page.
                      You may think that the league wasn’t created but it was!
                      Click on the league tab to see your league. You will see a
                      big white box with an arrow – you can use this to scroll
                      for leagues if you are part of more than one.
                    </p>
                    <p>
                      To invite people to your league, click on the icon next to
                      the next ‘copy league code to invite other players’. You
                      can then share this code for people to join!
                    </p>
                    <p>
                      To navigate the information, click on the small white
                      arrows next to ‘league details’ to see fixtures, results
                      and league table information and when a competition is
                      complete, you will see the winner here!
                    </p>
                    <img src="/images/htu-my-league.png" alt="login_image" />
                  </li>
                </ol>
                <h3 className="how">Starting a League/Competition</h3>
                <p>
                  The league managers are responsible for starting a
                  league/competition once all of the players have registered.
                </p>
                <ol>
                  <li>
                    <p>
                      Navigate to the teams section in your league and then
                      click ‘start’.
                    </p>
                  </li>
                  <li>
                    <p>Fixtures will then become available to see</p>
                  </li>
                  <li>
                    <p>The league table will now be populated</p>
                    <img src="/images/htu-m-screens.JPG" alt="login_image" />
                  </li>
                  <li>
                    <p>
                      To submit results navigate to the ‘submit results’ page to
                      enter results. You will see a loading screen as these are
                      registered.
                    </p>
                  </li>
                  <li>
                    <p>To see the results, go to the results page</p>
                  </li>
                  <li>
                    <p>The League table will then also be updated</p>
                  </li>
                  <li>
                    <p>
                      ONLY league managers can input the results. Once all games
                      have been played, the winner will be announced.
                    </p>
                    <img src="/images/htu-result1.JPG" alt="login_image" />
                    <img
                      className="mini"
                      src="/images/htu-result2.JPG"
                      alt="login_image"
                    />
                  </li>
                </ol>
                <h3 className="how">How to join a league or tournament</h3>
                <p>For this example, we will show you how to join a league.</p>
                <ol>
                  <li>
                    <p>
                      You will have been given a league code by the league
                      manager. You then need to select the ‘Leagues’ tab on the
                      homepage/dashboard and then click the red button that says
                      ‘join or create league’
                    </p>
                    <img src="/images/htu-join.png" alt="login_image" />
                  </li>
                  <li>
                    <p>
                      On the next page, click the middle tab ‘join league’. You
                      will then be given the option to enter a league code.
                      Enter your league code here and click ‘join league’. You
                      will now have access to this league!
                    </p>
                    <img src="/images/htu-enter-dets.png" alt="login_image" />
                  </li>
                </ol>
                <h3 className="how">TOP TIP for remote play!</h3>
                <p>
                  Try the below method when competing with friends remotely.
                </p>
                <ol>
                  <li>
                    <p>Create a WhatsApp group.</p>
                  </li>
                  <li>
                    <p>
                      Invite all league/tournament participants to WhatsApp
                      group.
                    </p>
                  </li>
                  <li>
                    <p>Participants send in their results.</p>
                  </li>
                  <li>
                    <p>
                      League/tournament host updates results to Play Rivalz
                      platform.
                    </p>
                  </li>
                </ol>
              </div>
            </NoticeWrapper>
          </div>
        </Curved>
        <Spacer height="10rem" />
      </div>
      <div className="b-holding">
        <div className="footing">
          <Selectors />
          {/* <Flexed>
            <FaFacebookF className="grey" />
            <FaInstagram className="grey" />
            <BsTwitter className="grey" />
            <FaYoutube className="grey" />
          </Flexed> */}
        </div>
      </div>
    </Wrapper>
  );
}

export default HowToUse;
