import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { RiTeamFill } from "react-icons/ri";
import Spacer from "../../../../components/spacer/Spacer";
import { toast } from "react-toast";
import MatchUps from "../../Leagues/components/MatchUps";
import { LoadInterface } from "../../Leagues/paths/Fixtures";
import { AppColors } from "../../../../config/AppColors";
import { getToken } from "../../../../utils";
import api from "../../../../api/api";
import FullButton from "../../../../components/buttons/FullButton";

const Wrapper = styled.div`
  position: relative;

  .none {
    color: ${AppColors.WHITE};
    padding: 0 1.5rem;
    text-align: center;
  }

  .holder {
    position: relative;
    padding: 0rem;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .btn-wrap {
    padding: 0 3rem 0 3rem;
  }
`;

const MatchWraps = styled.div`
  position: relative;
  margin: 1rem 0;
  padding: 0rem 1.5rem;

  .scores {
    padding: 0.25rem 1rem;
    border-radius: 1rem;
    background-color: ${AppColors.Pinkish};
    color: ${AppColors.WHITE};
  }
`;

function TournamentTeams({ details }) {
  const [fetching, setFetching] = useState(false);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (details) {
      getGameById();
    }
  }, [details]);

  const getGameById = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setFetching(true);
    const res = await api.getById("tornament", details?.id, "teams");
    if (res) {
      setFetching(false);
      setInfo(res);
    } else {
      setFetching(false);
      setInfo(null);
      console.log("tour err", res);
    }
  };

  const start = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setLoading(true);
    const res = await api.getById("tornament", details?.id, "start");
    if (res.status) {
      console.log("start", res);
      setLoading(false);
      setValue(res);
      toast.success(res.message);
    } else {
      setLoading(false);
      setValue(null);
      console.log("start err", res);
    }
  };

  console.log("det", info);

  return (
    <Wrapper>
      <Spacer height="1rem" />
      {fetching ? (
        <LoadInterface>
          <RiTeamFill className="trophy" />
        </LoadInterface>
      ) : info?.length > 0 ? (
        <div className="holder">
          <div className="container">
            {info?.map((x, index) => (
              <MatchWraps key={x?.id}>
                <MatchUps text={x?.team_name} type="team" position="home" />
              </MatchWraps>
            ))}
          </div>
          {value === null && (info.length == 4 || info.length == 8) && (
            <div className="btn-wrap">
              {" "}
              <FullButton
                bg="#f80033"
                color="white"
                isloading={loading}
                onClick={start}
              >
                Start
              </FullButton>
            </div>
          )}
        </div>
      ) : (
        <div className="none">
          <Spacer height="2rem" />
          <p>No team has joined your tournament</p>
        </div>
      )}
    </Wrapper>
  );
}

export default TournamentTeams;
