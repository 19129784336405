import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DefaultButton from "../../../../../components/buttons/DefaultButton";
import { AppColors } from "../../../../../config/AppColors";
import DarkInput from "../../../../../components/Inputs/DarkInput";
import Spacer from "../../../../../components/spacer/Spacer";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { getToken } from "../../../../../utils";
import api from "../../../../../api/api";
import ReactLoading from "react-loading";
import { toast } from "react-toast";
import Select from "react-select";

const Wrapper = styled.div`
  position: relative;

  p {
    color: ${AppColors.WHITE};
    padding: 8px 0;
    font-size: 0.8rem;
  }

  .holding {
    height: 40vh;
    position: relative;
    display: grid;
    place-items: center;
    div {
      margin: 0 auto;
    }
  }

  .mini-holding {
    height: 10vh;
    position: relative;
    display: grid;
    place-items: center;
    div {
      margin: 0 auto;
    }
  }

  .result {
    display: flex;
    align-items: center;
    gap: 10%;
    padding: 0 0.5rem;
    margin: 0.5rem 0;
    border-radius: 1rem;
    cursor: pointer;
    &:hover {
      background-color: hsl(224deg 29.41% 10% / 35%);
    }
  }

  .result-active {
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    margin: 0.5rem 0;
    gap: 10%;
    background-color: hsl(224deg 29.41% 10% / 65%);
    border-radius: 1rem;

    p {
      color: ${AppColors.Success} !important;
    }
  }

  .select__control--is-focused {
    background-color: ${AppColors.Pinkish};
    border-color: ${AppColors.Pinkish};
  }

  .select__control--menu-is-open {
    border-color: transparent !important;
    box-shadow: 0 0 0 1px transparent;
  }

  .css-13cymwt-control {
    background-color: ${AppColors.Pinkish};
    border-color: ${AppColors.Pinkish};
  }

  .select__control--is-disabled {
    background-color: ${AppColors.lightPink};
    border-color: ${AppColors.lightPink};
  }

  .css-1dimb5e-singleValue {
    color: ${AppColors.WHITE} !important;
  }

  .select--is-disabled {
    &:hover {
      cursor: pointer;
    }
  }
  .select__single-value--is-disabled {
    color: ${AppColors.BLACK} !important;
  }
  .css-b62m3t-container,
  .select--is-disabled {
    width: 60%;
  }
  .select__dropdown-indicator {
    color: ${AppColors.WHITE};
  }
  .select__clear-indicator,
  .css-1u9des2-indicatorSeparator,
  .select__indicator-separator {
    display: none;
  }

  #react-select-7-listbox li {
    &:hover {
      background-color: ${AppColors.Pinkish};
    }
  }
`;

const InputWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 1rem;
    left: 1rem;
    fill: ${AppColors.DarkGrey};
  }
`;

function LeagueCode({ search, holder, type }) {
  const [loading, setLoading] = useState(false);
  const [getting, setGetting] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [country, setCountry] = useState(null);
  const [teams, setTeams] = useState(null);
  const [code, setCode] = useState("");
  const [value, setValue] = useState(null);
  const [content, setContent] = useState("");
  const [queryVal, setQueryVal] = useState([]);
  const [indice, setIndice] = useState(0);
  const [selectedT, setSelectedT] = useState(null);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabledT, setIsDisabledT] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const team = [{ value: "", label: getting ? "..." : "Select team" }];
  const [isRtl, setIsRtl] = useState(false);
  useEffect(() => {
    if (code != "") {
      getEventByCode();
    }
  }, [code]);

  useEffect(() => {
    if (country != null) {
      getTeams();
    }
  }, [country]);

  const getEventByCode = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setLoading(true);
    let courier = {
      code: code,
    };
    const res = await api.create("tornament/byCode", courier);
    if (res) {
      setLoading(false);
      setCountry(res);
      setIsDisabledT(false);
    } else {
      setLoading(false);
      setCountry(null);
      console.log("team err", res);
    }
  };

  const getTeams = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    if (country?.data.teams) {
      setGetting(true);
      const res = await api.getAll(
        `team/country/${country?.data?.teams[0].country_id}`
      );
      if (res) {
        setGetting(false);
        const secondArrayIds = new Set(
          country?.data.teams.map((obj) => obj.id)
        );
        const filteredFirstArray = res.data.filter(
          (x) => !secondArrayIds.has(x.id)
        );
        setTeams(filteredFirstArray);
      } else {
        setGetting(false);
        setTeams(null);
        console.log("team err", res);
      }
    } else {
      toast.error("Code invalid");
    }
  };

  const joinRequest = async (e) => {
    e.preventDefault();
    if (selectedT?.id && code != "") {
      setRequesting(true);
      let courier = {
        code: code,
        team_id: selectedT?.id,
      };
      const res = await api.create("tornament/join", courier);
      if (res?.updated_at) {
        setRequesting(false);
        setValue(res);
        toast.success(`You have successfully joined a ${type}`);
      } else {
        setRequesting(false);
      }
    }
  };

  const querySearch = async (e, query) => {
    e.preventDefault();
    if (content != "") {
      setFetching(true);
      let courier = {
        type_id: type === "League" ? "1" : "2",
        name: query,
      };
      const res = await api.create("tornament/search", courier);
      if (res.status) {
        setFetching(false);
        console.log("value", res);
        setQueryVal(res?.data);
      } else {
        setFetching(false);
      }
    }
  };

  const formatInfo = (param) => {
    if (param) {
      const formatted = param.map((x) => {
        return {
          value: x.name,
          label: x.name,
          id: x.id,
          country_id: x.country_id,
          logo: x.logo,
          team_type: x.team_type,
        };
      });
      return formatted;
    }
    return [];
  };

  console.log("can", content);
  console.log("team", teams);
  return (
    <Wrapper>
      {loading ? (
        <div className="holding">
          <ReactLoading
            type={"balls"}
            color={"#f80033"}
            height={"20%"}
            width={"20%"}
          />
        </div>
      ) : (
        <>
          {search ? (
            <p>Search {type} name</p>
          ) : (
            <p>Input invite code to join {type}</p>
          )}
          {search ? (
            <InputWrapper>
              <DarkInput
                onChange={(e) => {
                  setContent(e.target.value);
                  if (e.target.value.length > 1) {
                    querySearch(e, e.target.value);
                  }
                }}
              />
              <FaSearch />
            </InputWrapper>
          ) : (
            <InputWrapper>
              <DarkInput
                type="text"
                placeholder={holder}
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
            </InputWrapper>
          )}

          {fetching ? (
            <div className="mini-holding">
              <ReactLoading
                type={"balls"}
                color={"#f80033"}
                height={"15%"}
                width={"15%"}
              />
            </div>
          ) : (
            queryVal && (
              <>
                {queryVal?.map((x, index) => (
                  <div
                    key={x.id}
                    className={indice === x.id ? "result-active" : "result"}
                    onClick={() => {
                      setCode(x.code);
                      setIndice(x.id);
                    }}
                  >
                    <p>{x?.name}</p>
                    <p>{x.code}</p>
                  </div>
                ))}
              </>
            )
          )}
          <Spacer height="1rem" />
          {country != null && (
            <>
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={team[0]}
                isDisabled={isDisabledT}
                isLoading={isLoading}
                isClearable={isClearable}
                isRtl={isRtl}
                onChange={setSelectedT}
                isSearchable={isSearchable}
                name="team"
                options={formatInfo(teams)}
              />
              <Spacer height="1rem" />
            </>
          )}
          <Spacer height="1rem" />
          <DefaultButton
            size="half"
            isloading={requesting}
            onClick={(e) => {
              joinRequest(e);
            }}
          >
            Join {type}
          </DefaultButton>
        </>
      )}
    </Wrapper>
  );
}

export default LeagueCode;
