import React from "react";
import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { AppColors } from "../../config/AppColors";
import Spacer from "../spacer/Spacer";

const Wrapped = styled.div`
  position: relative;
  padding: 0 10%;

  @media (max-width: 377px) {
    padding: 0 5%;
  }

  .contain .react-tabs__tab-list {
    border-bottom: none;
    background-color: ${AppColors.tabsGrey};
    margin: 0rem 0rem 1rem 0;
    border-radius: 2rem;
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .contain .react-tabs__tab {
    bottom: 0px;
    padding: 0.75rem 9.9%;
    min-width: 4rem;
    text-align: center;
    color: ${AppColors.WHITE};
    font-size: 0.7rem;
    font-weight: 400;
    width: 29.9%;

    @media (max-width: 500px) {
      width: 33.5%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 470px) and (min-width: 378px) {
      padding: 0.75rem 1rem;
    }

    @media (max-width: 377px) {
      padding: 0.75rem;
    }
  }
  .contain .react-tabs__tab--selected {
    background-color: ${AppColors.Pinkish};
    border: none;
    border-radius: 2rem;
  }

  .contain .react-tabs__tab:focus:after {
    background-color: transparent;
  }
`;

function TourJoinTab({
  fTitle,
  sTitle,
  tTitle,
  fContent,
  sContent,
  tContent,
  league,
}) {
  return (
    <Wrapped>
      <div className="contain">
        <Tabs>
          <TabList>
            <Tab>{fTitle}</Tab>
            <Tab>{sTitle}</Tab>
            {league && <Tab>{tTitle}</Tab>}
          </TabList>
          <TabPanel>{fContent}</TabPanel>
          <TabPanel>{sContent}</TabPanel>
          {league && <TabPanel>{tContent}</TabPanel>}
        </Tabs>
      </div>
    </Wrapped>
  );
}

export default TourJoinTab;
