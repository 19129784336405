import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  team: {},
};

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    teamSaved: {
      reducer(state, action) {
        state.team = action.payload;
      },
      prepare(content) {
        return {
          payload: {
            content,
          },
        };
      },
    },
    clearTeam: {
      reducer(state) {
        state.team = {};
      },
    },
  },
});

export const selectTeam = (state) => state.team.team.content;

export const { teamSaved, clearTeam } = teamSlice.actions;

export default teamSlice.reducer;
