import React from "react";
import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { AppColors } from "../../config/AppColors";
import Spacer from "../spacer/Spacer";

const Wrapper = styled.div`
  position: relative;
  padding: 0 2%;
  @media (max-width: 377px) {
    padding: 0 3%;
  }

  .containing .react-tabs__tab-list {
    border-bottom: none;
    background-color: ${AppColors.tabsGrey};
    margin: 0rem 0rem 1rem 0;
    border-radius: 2rem;
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .containing .react-tabs__tab {
    bottom: 0px;
    padding: 0.75rem 10%;
    min-width: 4rem;
    text-align: center;
    color: ${AppColors.WHITE};
    font-size: 0.7rem;
    font-weight: 400;
    width: 29.6%;

    @media (max-width: 500px) {
      width: 35%;
    }

    @media (max-width: 470px) and (min-width: 378px) {
      padding: 0.75rem 1rem;
    }

    @media (max-width: 377px) {
      padding: 0.75rem;
    }
  }
  .containing .react-tabs__tab--selected {
    background-color: ${AppColors.Pinkish};
    border: none;
    border-radius: 2rem;
  }

  .containing .react-tabs__tab:focus:after {
    background-color: transparent;
  }
`;

function MiniTab({ fTitle, sTitle, fContent, sContent }) {
  return (
    <Wrapper>
      <div className="containing">
        <Tabs>
          <TabList>
            <Tab>{fTitle}</Tab>
            <Tab>{sTitle}</Tab>
          </TabList>
          <TabPanel>{fContent}</TabPanel>
          <TabPanel>{sContent}</TabPanel>
        </Tabs>
      </div>
    </Wrapper>
  );
}

export default MiniTab;
