import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MatchUps from "../Leagues/components/MatchUps";
import { AppColors } from "../../../config/AppColors";
import axios from "axios";
import { getToken } from "../../../utils";
import api from "../../../api/api";
import ReactLoading from "react-loading";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import PinkButton from "../../../components/buttons/PinkButton";

const Wrapper = styled.div`
  position: relative;

  .none {
    color: ${AppColors.WHITE};
    padding: 0 1.5rem;
    text-align: center;
  }

  .holding {
    height: 40vh;
    position: relative;
    display: grid;
    place-items: center;
    div {
      margin: 0 auto;
    }
  }

  .view {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 0.5rem;
    font-size: 0.9rem;
    @media (max-width: 768px) {
      font-size: 0.7rem;
      font-weight: 100;
    }
    span {
      cursor: pointer;
    }
  }
`;

export const MatchWraps = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;

  .header {
    background-color: ${AppColors.PURPLE};
    text-align: center;

    p {
      color: ${AppColors.WHITE};
      font-size: 0.85rem;
    }
  }

  .pos {
    display: flex;
    justify-content: center;
    gap: 3%;
    align-items: center;
    width: 25%;
  }

  .team {
    width: 60%;
    padding: 0rem 1rem;
  }

  .glass {
    background: rgb(193 193 193 / 15%);
    display: flex;
    justify-content: center;
    gap: 3%;
    align-items: center;
    width: 25%;

    p {
      margin: 8px 0;
      color: ${AppColors.WHITE};
    }

    .faint {
      fill: ${AppColors.lightPink};
    }

    .clear {
      fill: ${AppColors.Pinkish};
      margin-left: -0.5rem;
    }

    .icon-wrap {
      display: flex;
    }
  }
`;

function Ranking({ type, getCurrent }) {
  const [fetching, setFetching] = useState(false);
  const [info, setInfo] = useState([]);
  const navigate = useNavigate();
  const [checker, setChecker] = useState([]);

  useEffect(() => {
    if (type == "league") {
      getLeagues();
      getCurrent(0);
      console.log("league err");
    } else {
      getTours();
      getCurrent(1);
      console.log("tour err");
    }
  }, []);

  const getLeagues = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setFetching(true);
    const res = await api.getAll("tornament/dashboard");
    if (res) {
      setFetching(false);
      setChecker(res);
      const otherFiltered = res?.data.Stats.filter((x) => x.type == "league");

      setInfo(otherFiltered);
    } else {
      setFetching(false);
      setInfo([]);
      console.log("league err", res);
    }
  };
  //new property to use
  const getTours = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setFetching(true);
    const res = await api.getAll("tornament/dashboard");
    if (res) {
      setFetching(false);
      setChecker(res);
      const otherFiltered = res?.data.Stats.filter(
        (x) => x.type == "tornament"
      );
      setInfo(otherFiltered);
    } else {
      setFetching(false);
      setInfo([]);
      console.log("league err", res);
    }
  };

  console.log("ao", info);

  const sortedData =
    info?.length > 0 ? info?.slice().sort((a, b) => b.points - a.points) : info;

  console.log("sort");

  return (
    <Wrapper>
      {fetching ? (
        <div className="holding">
          <ReactLoading
            type={"balls"}
            color={"#f80033"}
            height={"20%"}
            width={"20%"}
          />
        </div>
      ) : sortedData?.length > 0 && checker?.data?.is_started ? (
        <>
          <MatchWraps>
            <div className="header pos">
              <p>Rank</p>
            </div>
            <div className="header team">
              <p>League</p>
            </div>
          </MatchWraps>
          {sortedData?.map((x, index) => (
            <MatchWraps key={index}>
              <div className="glass">
                <p>{x.pos}</p>
              </div>
              <MatchUps text={x?.Leaque} type="rank" />
            </MatchWraps>
          ))}
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <p
            style={{
              textAlign: "center",
              marginTop: "2rem",
              marginBottom: "1rem",
              fontSize: "0.85rem",
            }}
          >
            You are not currently in any Competition
          </p>
          <PinkButton
            onClick={() => {
              navigate("/" + type + "s");
            }}
            size="large"
          >
            Join or Create {type}
          </PinkButton>
        </div>
      )}
      {sortedData.length > 0 && (
        <p className="view">
          <span
            onClick={() => {
              if (type == "league") {
                navigate("/leagues");
              } else navigate("/tournaments");
            }}
          >
            View all <HiOutlineArrowNarrowRight />
          </span>
        </p>
      )}
    </Wrapper>
  );
}

export default Ranking;
