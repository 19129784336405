import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MatchUps from "../components/MatchUps";
import { AppColors } from "../../../../config/AppColors";
import { BsChevronRight } from "react-icons/bs";
import axios from "axios";
import { ImTrophy } from "react-icons/im";
import { getToken } from "../../../../utils";
import api from "../../../../api/api";
import { LoadInterface } from "./Fixtures";
import Spacer from "../../../../components/spacer/Spacer";

const Wrapper = styled.div`
  position: relative;

  .none {
    color: ${AppColors.WHITE};
    padding: 0 1.5rem;
    text-align: center;
  }
`;

const MatchWraps = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;

  .head {
    background-color: ${AppColors.PURPLE};
    text-align: center;

    p {
      color: ${AppColors.WHITE};
      font-size: 0.85rem;
    }
  }

  .pos {
    width: 12%;
  }

  .gf {
    width: 7%;
  }
  .ga {
    width: 7%;
  }
  .gd {
    width: 7%;
  }
  .right {
    width: 7%;
  }

  .team {
    width: 26%;
    @media (max-width: 400px) {
      width: 28.5%;
    }
  }

  .glass {
    background: rgb(193 193 193 / 15%);
    display: flex;
    justify-content: center;
    gap: 3%;
    align-items: center;
    width: 12%;

    p {
      margin: 8px 0;
      color: ${AppColors.WHITE};
    }

    .faint {
      fill: ${AppColors.lightPink};
    }

    .clear {
      fill: ${AppColors.Pinkish};
      margin-left: -0.5rem;
    }

    .icon-wrap {
      display: flex;
    }
  }
  .points {
    background: rgb(193 193 193 / 15%);
    width: 7%;
    text-align: center;

    p {
      margin: 8px 0;
      color: ${AppColors.WHITE};
    }
  }
`;

function LeagueTables({ details }) {
  const [fetching, setFetching] = useState(false);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (details) {
      getGameById();
    }
  }, [details]);

  const getGameById = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setFetching(true);
    const res = await api.getById("tornament", details?.id, "info");
    if (res) {
      setFetching(false);
      console.log("rsa", res);
      setInfo(res?.data?.table);
    } else {
      setFetching(false);
      setInfo(null);
      console.log("tour err", res);
    }
  };

  console.log("ao", info);

  const sortedData =
    info?.length > 0 ? info?.slice().sort((a, b) => b.points - a.points) : info;

  return (
    <Wrapper>
      {fetching ? (
        <LoadInterface>
          <ImTrophy className="trophy" />
        </LoadInterface>
      ) : info?.length > 0 ? (
        <>
          <MatchWraps>
            <div className="head pos">
              <p>POS</p>
            </div>
            <div className="head team">
              <p>TEAM</p>
            </div>
            <div className="head right">
              <p>GP</p>
            </div>
            <div className="head gd">
              <p>W</p>
            </div>
            <div className="head gd">
              <p>D</p>
            </div>
            <div className="head gd">
              <p>L</p>
            </div>
            <div className="head gf">
              <p>GF</p>
            </div>
            <div className="head ga">
              <p>GA</p>
            </div>
            <div className="head gd">
              <p>GD</p>
            </div>
            <div className="head gd">
              <p>P</p>
            </div>
          </MatchWraps>
          {sortedData?.map((x, index) => (
            <MatchWraps key={index}>
              <div className="glass">
                <div className="icon-wrap">
                  <BsChevronRight className="faint" />
                  <BsChevronRight className="clear" />
                </div>
                <p>{index + 1}</p>
              </div>
              <MatchUps text={x?.team} type="table" />
              <div className="points">
                <p>{x?.Pl} </p>
              </div>
              <div className="points">
                <p>{x?.W} </p>
              </div>
              <div className="points">
                <p>{x?.D} </p>
              </div>
              <div className="points">
                <p>{x?.L} </p>
              </div>
              <div className="points">
                <p>{x?.GF}</p>
              </div>
              <div className="points">
                <p>{x?.GA}</p>
              </div>
              <div className="points">
                <p>{x?.GD} </p>
              </div>
              <div className="points">
                <p>{x.Pts ? x.Pts : 0}</p>
              </div>
            </MatchWraps>
          ))}
        </>
      ) : (
        <div className="none">
          <Spacer height="2rem" />
          <p>League is yet to kickoff</p>
        </div>
      )}
    </Wrapper>
  );
}

export default LeagueTables;
