import React from "react";
import styled from "styled-components";
import MiniTab from "../../../../components/tabs/MiniTab";
import LeaguePaths from "./LeaguePaths";
import OtherPaths from "./OtherPaths";

const Wrapper = styled.div`
  position: relative;
`;

function AllLeagues({ fTitle, sTitle, getCurrent }) {
  return (
    <Wrapper>
      <MiniTab
        fTitle="My league"
        sTitle="Other leagues"
        fContent={<LeaguePaths getCurrent={getCurrent} />}
        sContent={<OtherPaths />}
      />
    </Wrapper>
  );
}

export default AllLeagues;
