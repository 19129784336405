import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ArrowNav from "../../../../components/Navigation/ArrowNav";
import Fixtures from "./Fixtures";
import LeagueResults from "./LeagueResults";
import LeagueTables from "./LeagueTables";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import api from "../../../../api/api";
import { getToken } from "../../../../utils";
import ReactLoading from "react-loading";
import { AppColors } from "../../../../config/AppColors";
import LeagueDetails from "./LeagueDetails";
import LeagueTeams from "./LeagueTeams";
import SubmitResults from "../../Tournaments/paths/SubmitResults";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Wrapper = styled.div`
  position: relative;
  margin-top: -1rem;

  .holding {
    height: 40vh;
    position: relative;
    display: grid;
    place-items: center;
    div {
      margin: 0 auto;
    }
  }
  .flex-container {
    width: 300px;
    margin: 0 auto 1rem auto;

    .react-multiple-carousel__arrow {
      box-shadow: 0px 7.27273px 26.1818px #f44;
    }

    .react-multiple-carousel__arrow--left {
      margin-left: -0.5rem !important;
    }
    .react-multiple-carousel__arrow--right {
      right: 1rem !important;
    }
    .react-multi-carousel-item {
      width: 300px !important;
    }
    .react-multi-carousel-track {
      gap: 0rem;
    }
    .react-multi-carousel-item--active {
      text-align: center;
      color: ${AppColors.Pinkish};
      font-size: 1.2rem;
      font-weight: 700;
    }
  }
`;

function OtherPaths() {
  const [elements, setElements] = useState([
    "FIXTURES",
    "RESULTS",
    "LEAGUE TABLE",
  ]);
  const [index, setIndex] = useState(0);
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ost, setOst] = useState(0);
  const [check, setCheck] = useState(false);

  const updateDisplay = () => {
    const currentElement = elements[index];
    return currentElement;
  };

  useEffect(() => {
    getLeagues();
  }, []);

  const handleRemoveElement = () => {
    const list = [...elements];
    console.log(list.slice(0, list.length - 1));
    setElements(list.slice(0, list.length - 1));
  };

  useEffect(() => {
    if (check) {
      handleRemoveElement();
    }
  }, [check]);

  const RightLoopButtonClick = () => {
    setIndex((index + 1) % elements.length);
  };

  const LeftLoopButtonClick = () => {
    setIndex((index + (elements.length - 1)) % elements.length);
  };

  const getLeagues = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setLoading(true);
    const res = await api.getAll("tornament");
    if (res) {
      setLoading(false);
      console.log("arc", res);
      const filtered = res?.data?.joinedevents.filter((x) => x.type_id === 1);
      if (filtered?.length < 1) {
        setCheck(true);
      }

      setLeagues(filtered);
    } else {
      setLoading(false);
      setLeagues([]);
      console.log("league err", res);
    }
  };

  return (
    <Wrapper>
      {!loading ? (
        <>
          <div className="flex-container">
            <Carousel
              autoPlay={false}
              responsive={responsive}
              arrows={true}
              partialVisible={true}
              beforeChange={(nextSlide, { currentSlide, onMove }) => {
                setOst(nextSlide);
              }}
            >
              {leagues.map((x) => (
                <p key={x.id}>{x.event_name}</p>
              ))}
            </Carousel>
          </div>
          <ArrowNav
            onRightClick={() => RightLoopButtonClick()}
            onLeftClick={() => LeftLoopButtonClick()}
          >
            {updateDisplay()}
          </ArrowNav>
          <>
            {index === 0 && <Fixtures details={leagues[ost]} />}
            {index === 1 && <LeagueResults details={leagues[ost]} />}
            {index === 2 && <LeagueTables details={leagues[ost]} />}
          </>
        </>
      ) : (
        <div className="holding">
          <ReactLoading
            type={"balls"}
            color={"#f80033"}
            height={"20%"}
            width={"20%"}
          />
        </div>
      )}
    </Wrapper>
  );
}

export default OtherPaths;
