import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FaRegUser } from "react-icons/fa";
import { FaRegEnvelope } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import Input from "../Inputs/Input";
import { AppColors } from "../../config/AppColors";
import DefaultButton from "../buttons/DefaultButton";
import Spacer from "../spacer/Spacer";
import { useNavigate } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import PinkButton from "../buttons/PinkButton";

const Wrapper = styled.div`
  position: relative;
`;

const FormWrap = styled.form`
  position: relative;
  background: #fff;
  box-sizing: border-box;
  padding: 3em 3em 2em 3em;
  border-radius: 2rem;
  @media (max-width: 768px) {
    padding: 1rem 2rem;
  }

  h4 {
    @media (max-width: 768px) {
      font-size: 0.85rem;
      margin: 8px 0;
    }
  }

  h2 {
    text-align: center;

    @media (max-width: 768px) {
      margin: 8px 0;
    }
  }
  span {
    color: ${AppColors.red};
    cursor: pointer;
  }

  .f-p {
    text-align: left !important;
    padding-left: 2rem;
    font-weight: 600;
    display: block;
    cursor: pointer;
  }
  .bottom-form {
    display: grid;
    justify-content: center;
    align-items: center;
  }
`;

export const WrapInput = styled.div`
  position: relative;
  padding: 0.3rem 0;
  svg {
    position: absolute;
    left: 1.8rem;
    top: 1.3rem;
    cursor: pointer;
    fill: ${AppColors.LIGHTGREY};

    @media (max-width: 500px) {
      left: 1.5rem;
      top: 1.1rem;
    }
  }

  .lock {
    position: absolute;
    left: 1.8rem;
    top: 1.3rem;
    cursor: pointer;
    fill: ${AppColors.LIGHTGREY};

    @media (max-width: 500px) {
      left: 1.5rem;
      top: 1.1rem;
    }
  }

  .eye {
    position: absolute;
    top: 1.3rem;
    left: 85%;
    cursor: pointer;
    fill: ${AppColors.LIGHTGREY};

    @media (max-width: 500px) {
      left: 85%;
      top: 1.1rem;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const NumInput = styled.input`
  display: block;
  border-radius: 2rem;
  padding: 1.3em 1em 1.3em 4em;
  width: 100%;
  box-sizing: border-box;
  background-color: ${AppColors.SLGrey};
  border: none;
  font-weight: 700;

  ::placeholder {
    color: ${AppColors.BLACK};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 360px) {
    padding: 1.3em 1em 1.3em 3em;
  }
`;

const Chip = styled.div`
  position: relative;
  margin: 0.2rem 0;

  h3 {
    margin: 2px 0;
  }
  div {
    display: flex;
    justify-content: space-evenly;
  }
  div > input {
    margin-right: 0px !important;
  }
  input {
    width: 1.5rem !important;
    height: 2rem !important;
    margin: 1rem 0.5rem;
    border: none;
    background-color: ${AppColors.SLGrey};

    &:focus-visible {
      outline: ${AppColors.Pinkish} auto 1px;
    }
  }
`;

function Form({
  type,
  getDetails,
  getPayload,
  getForgotPayload,
  loading,
  request,
}) {
  const navigate = useNavigate();
  const inputElem = useRef();
  const [show, setShow] = useState(true);
  const [hide, setHide] = useState(true);
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [match, setMatch] = useState(true);
  const [loginVal, setLoginVal] = useState("");

  const data = {
    email: email,
    userName: userName,
    password: password,
    cPassword: cPassword,
    match: match,
    loginVal: loginVal,
  };

  useEffect(() => {
    if (
      email != "" &&
      userName !== "" &&
      password !== "" &&
      cPassword !== "" &&
      match
    ) {
      getDetails(data);
    }
    if (loginVal != "" && password != "") {
      getPayload(data);
    }
    if (type === "forgot") {
      if (email != "") {
        getForgotPayload(data);
      }
    }
  }, [email, userName, password, cPassword, match, loginVal]);

  const handleOnWheel = (e) => {
    // The blur event fires when an element has lost focus. The event does not bubble,
    inputElem.current.blur();
  };

  return (
    <Wrapper>
      <FormWrap>
        {type === "login" && <h2>Login</h2>}
        {type === "forgot" && <h2>Forgot Password</h2>}
        {type === "reg" && (
          <WrapInput>
            <Input
              placeholder="Username"
              type="text"
              onChange={(e) => setUserName(e.target.value)}
              required={true}
            />
            <FaRegUser />
          </WrapInput>
        )}
        {type === "login" && (
          <WrapInput>
            <Input
              placeholder="Email"
              type="text"
              onChange={(e) => setLoginVal(e.target.value)}
              required={true}
            />
            <FaRegUser />
          </WrapInput>
        )}
        {(type === "reg" || type === "forgot") && (
          <WrapInput>
            <Input
              placeholder="Email"
              required={true}
              type="text"
              onChange={(e) => setEmail(e.target.value)}
            />
            <FaRegEnvelope />
          </WrapInput>
        )}
        {type === "forgot" && (
          <>
            <Spacer height="1rem" />
            <DefaultButton
              float="left"
              isloading={loading}
              onClick={(e) => {
                request(e);
              }}
            >
              Send
            </DefaultButton>
          </>
        )}
        {(type === "reg" || type === "login") && (
          <WrapInput>
            <Input
              placeholder="Password"
              type={show ? "password" : "text"}
              required={true}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FaLock className="lock" />
            {show ? (
              <FaEye className="eye" onClick={() => setShow(!show)} />
            ) : (
              <FaEyeSlash className="eye" onClick={() => setShow(!show)} />
            )}
          </WrapInput>
        )}
        {type === "login" && (
          <em className="f-p" onClick={() => navigate("/forgot-password")}>
            Forgot Password?
          </em>
        )}
        {type === "reg" && (
          <WrapInput>
            <Input
              placeholder="Confirm password"
              type={hide ? "password" : "text"}
              required={true}
              disabled={password?.length > 0 ? false : true}
              onChange={(e) => {
                setCPassword(e.target.value);
                if (e.target.value != password) {
                  setMatch(false);
                } else {
                  setMatch(true);
                }
              }}
              style={{
                color: match ? `${AppColors.BLACK}` : `${AppColors.red}`,
              }}
            />
            <FaLock className="lock" />
            {hide ? (
              <FaEye className="eye" onClick={() => setHide(!hide)} />
            ) : (
              <FaEyeSlash className="eye" onClick={() => setHide(!hide)} />
            )}
          </WrapInput>
        )}
        {
          <Spacer
            height={
              type === "reg" ? "1rem" : type === "forgot" ? "0rem" : "2rem"
            }
          />
        }
        <div className="bottom-form">
          {type != "forgot" && (
            <DefaultButton
              isloading={loading}
              onClick={(e) => {
                type === "reg" ? request(e) : request(e);
              }}
            >
              {type === "reg" ? "Sign Up" : "Login"}
            </DefaultButton>
          )}
          <Spacer height="1rem" />
          {type === "reg" && (
            <h4>
              Have an acccount?{" "}
              <span onClick={() => navigate("/")}>Log in</span>
            </h4>
          )}
        </div>
      </FormWrap>
    </Wrapper>
  );
}

export default Form;
