import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  margin: 3rem 0;
  display: grid;
  place-items: center;

  @media (max-width: 768px) {
    margin: 3rem auto;
  }

  img {
    height: 5rem;

    @media (max-width: 768px) {
      width: 172px;
      height: 3.3rem;
    }
  }
`;

function Logo() {
  return (
    <Wrapper>
      <img src="/images/Playrivalz logo.png" alt="logo" />
    </Wrapper>
  );
}

export default Logo;
