import React from "react";
import styled from "styled-components";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { AppColors } from "../../config/AppColors";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: transparent;
  color: ${AppColors.WHITE};

  svg {
    fill: ${AppColors.WHITE};
    width: 1.5em;
    cursor: pointer;
  }
`;

function ArrowNav({ children, onRightClick, onLeftClick }) {
  return (
    <Wrapper>
      <BsChevronLeft onClick={onLeftClick} />
      {children}
      <BsChevronRight onClick={onRightClick} />
    </Wrapper>
  );
}

export default ArrowNav;
