import React, { useEffect, useState } from "react";
import { BsTwitter } from "react-icons/bs";
import {
  FaFacebookF,
  FaInstagram,
  FaRegEnvelope,
  FaYoutube,
} from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Form from "../components/forms/Form";
import Logo from "../components/Logo/Logo";
import Spacer from "../components/spacer/Spacer";
import { AppColors } from "../config/AppColors";
import api from "../api/api";
import { toast } from "react-toast";
import PinInput from "../components/Inputs/PinInput";
import DefaultButton from "../components/buttons/DefaultButton";

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 5vh 10vw;

  @media (max-width: 768px) {
    padding: 0rem;
    width: -webkit-fill-available;
    overflow: hidden !important;
  }

  h1 {
    color: ${AppColors.WHITE};
    padding: 1rem 0;
  }

  .sign-route {
    color: ${AppColors.WHITE};

    span {
      color: ${AppColors.red};
      cursor: pointer;
    }
  }
  .connect {
    color: ${AppColors.DarkGrey};
  }
`;

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  .otp-wrap {
    position: relative;
    background: #fff;
    padding: 3em;
    border-radius: 2rem;

    @media (max-width: 768px) {
      padding: 2em 1em;
    }
  }
`;

export const Flexed = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  .svg-wrap-e {
    padding: 0.5rem;
    background-color: ${AppColors.red};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .svg-wrap-f {
    padding: 0.5rem;
    background-color: ${AppColors.FBblue};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .svg-wrap-t {
    padding: 0.5rem;
    background-color: ${AppColors.TwitterBlue};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .svg-wrap-g {
    padding: 0.5rem;
    background-color: ${AppColors.WHITE};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .e,
  .f,
  .t {
    fill: ${AppColors.WHITE};
  }

  .grey {
    fill: ${AppColors.LIGHTGREY};
    margin: 0 0.8rem;
    font-size: 1.2rem;
  }
`;

function VerifyOTP() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  useEffect(() => {
    setEmail(location.search.slice(1));

    if (email.length > 4 && code.length > 5) {
      confirmOTP();
    }
  }, [code]);

  const confirmOTP = async (e) => {
    setLoading(true);
    let courier = {
      email: email,
      code: code,
    };
    const res = await api.create("verifycode", courier);
    if (res?.status === true) {
      setLoading(false);
      console.log(res);
      toast.success(res.message);
      navigate("/reset-password?" + res?.data.email);
    } else {
      setLoading(false);
    }
  };

  const getPin = (data) => {
    setCode(data);
  };

  return (
    <Wrapper>
      <div className="inner-wrapper">
        <Logo />
        <FormWrapper>
          <div className="otp-wrap">
            <PinInput header="Enter OTP" getPin={getPin} />
            <Spacer height="1.5rem" />
            <DefaultButton isloading={loading}>Confirm</DefaultButton>
          </div>
        </FormWrapper>
        <Spacer height="1.5rem" />
        <Flexed>
          <div className="svg-wrap-e">
            <FaRegEnvelope className="e" />
          </div>
          <div className="svg-wrap-f">
            <FaFacebookF className="f" />
          </div>
          <div className="svg-wrap-t">
            <BsTwitter className="t" />
          </div>
          <div className="svg-wrap-g">
            <FcGoogle className="g" />
          </div>
        </Flexed>
        <Spacer height="2rem" />
        <Flexed>
          <FaFacebookF className="grey" />
          <FaInstagram className="grey" />
          <BsTwitter className="grey" />
          <FaYoutube className="grey" />
        </Flexed>
      </div>
    </Wrapper>
  );
}

export default VerifyOTP;
