import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import Spacer from "../../../../components/spacer/Spacer";
import { AppColors } from "../../../../config/AppColors";
import CustomButton from "../../../../components/buttons/CustomButton";
import PinkButton from "../../../../components/buttons/PinkButton";
import axios from "axios";
import { getToken } from "../../../../utils";
import api from "../../../../api/api";
import { toast } from "react-toast";
import moment from "moment";
import AppLoader from "../../../../components/loader/AppLoader";
import Switch from "react-switch";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: hsl(224deg 29.41% 10% / 55%);
  h4 {
    color: ${AppColors.WHITE};
    font-size: 1rem;
    margin: 8px 0;
  }

  p {
    margin: 0 0 8px 0;
    font-size: 0.8rem;
    color: ${AppColors.DarkGrey};
  }

  .select__control--is-focused {
    background-color: ${AppColors.Pinkish};
    border-color: ${AppColors.Pinkish};
  }

  .select__control--menu-is-open {
    border-color: transparent !important;
    box-shadow: 0 0 0 1px transparent;
  }

  .css-13cymwt-control {
    background-color: ${AppColors.Pinkish};
    border-color: ${AppColors.Pinkish};
  }

  .select__control--is-disabled {
    background-color: ${AppColors.lightPink};
    border-color: ${AppColors.lightPink};
  }

  .css-1dimb5e-singleValue {
    color: ${AppColors.WHITE} !important;
  }

  .select--is-disabled {
    &:hover {
      cursor: pointer;
    }
  }
  .select__single-value--is-disabled {
    color: ${AppColors.BLACK} !important;
  }
  .css-b62m3t-container,
  .select--is-disabled {
    width: 100%;
  }
  .select__dropdown-indicator {
    color: ${AppColors.WHITE};
  }
  .select__clear-indicator,
  .css-1u9des2-indicatorSeparator,
  .select__indicator-separator {
    display: none;
  }

  #react-select-7-listbox li {
    &:hover {
      background-color: ${AppColors.Pinkish};
    }
  }
`;
const Inner = styled.div`
  position: relative;
  display: grid;
  padding: 0 2rem;
`;

const Header = styled.h2`
  position: relative;
  background: rgb(193 193 193 / 15%);
  color: ${AppColors.WHITE};
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
  padding: 1rem 0;
  text-align: center;

  .back {
    position: absolute;
    left: 5%;
    cursor: pointer;
  }
`;

function LeagueRules({ getRules, type, country, data }) {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabledT, setIsDisabledT] = useState(true);
  const [isDisabledL, setIsDisabledL] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [getting, setGetting] = useState(false);
  const [teams, setTeams] = useState(null);
  const [leagues, setLeagues] = useState(null);
  const [selectedC, setSelectedC] = useState(null);
  const [selectedT, setSelectedT] = useState(null);
  const [selectedL, setSelectedL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [homeAway, setHomeAway] = useState(false);
  const [MTime, setMTime] = useState(null);
  const startDate = moment().format("YYYY-MM-DD");
  const endDate = moment(startDate).add(1, "months").format("YYYY-MM-DD");
  const options = [{ value: "", label: "Select Nation" }];
  const times = [
    { value: "", label: "Select Time" },
    { value: "5", label: "5 minutes" },
    { value: "10", label: "10 minutes" },
    { value: "15", label: "15 minutes" },
  ];
  const league = [{ value: "", label: getting ? "..." : "Select League" }];
  const team = [{ value: "", label: getting ? "..." : "Select team" }];
  const formatToNameId = (param) => {
    if (param) {
      const formatted = param.map((x) => {
        return {
          value: x.name,
          label: x.name,
          id: x.id,
        };
      });
      return formatted;
    }
    return [];
  };

  const formatInfo = (param) => {
    if (param) {
      const formatted = param.map((x) => {
        return {
          value: x.name,
          label: x.name,
          id: x.id,
          country_id: x.country_id,
          logo: x.logo,
          team_type: x.team_type,
        };
      });
      return formatted;
    }
    return [];
  };

  useEffect(() => {
    if (selectedC !== null) {
      getTeams();
      getLeagues();
      setIsDisabledT(false);
    }
  }, [selectedC]);

  const getTeams = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setGetting(true);
    const res = await api.getAll(`team/country/${selectedC?.id}`);
    if (res) {
      setGetting(false);
      setTeams(res?.data);
    } else {
      setGetting(false);
      setTeams(null);
      console.log("team err", res);
    }
  };

  const getLeagues = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setGetting(true);
    const res = await api.getAll(`league/${selectedC?.id}`);
    if (res) {
      setGetting(false);
      setLeagues(res?.data);
    } else {
      setGetting(false);
      setTeams(null);
      console.log("team err", res);
    }
  };

  const createLeague = async (e) => {
    e.preventDefault();
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    if (type == "League") {
      if (data?.number > 3 && data?.name != "") {
        setLoading(true);
        let carrier = {
          name: data?.name,
          start_date: startDate,
          end_date: endDate,
          type_id: type === "League" ? 1 : 2,
          is_home_away: homeAway,
          banner: "ddddd",
          team_id: selectedT.id,
          country_id: selectedC.id,
          league_id: selectedL.id,
          number_of_teams: parseInt(data?.number),
          is_owner_participate: true,
          is_private: data?.private,
        };
        const res = await api.create("tornament", carrier);
        if (res.status) {
          setLoading(false);
          toast.success(
            type === "League"
              ? "League created Successfully"
              : "Tournament created Successfully"
          );
          getRules(false);
        } else {
          setLoading(false);
          console.log("league err", res);
        }
      } else {
        toast.info("Minimum number of teams is 4");
      }
    } else {
      if (
        (data?.number.value == 4 ||
          data?.number.value == 8 ||
          data?.number.value == 16) &&
        data?.name != ""
      ) {
        setLoading(true);
        let carrier = {
          name: data?.name,
          start_date: startDate,
          end_date: endDate,
          type_id: type === "League" ? 1 : 2,
          is_home_away: true,
          banner:
            "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.adidas.com%2Fus%2Fucl-finale-madrid-top-training-ball%2FDN8676.html&psig=AOvVaw0lx5j6WJt7vQY1smEB1oB5&ust=1691932986955000&source=images&cd=vfe&opi=89978449&ved=0CBAQjRxqFwoTCMCUudeb14ADFQAAAAAdAAAAABAH",
          number_of_teams: parseInt(data?.number.value),
          is_owner_participate: true,
          is_private: data?.private,
          team_id: selectedT.id,
          country_id: selectedC.id,
          league_id: selectedL.id,
        };
        const res = await api.create("tornament", carrier);
        if (res.status) {
          setLoading(false);
          toast.success(
            type === "League"
              ? "League created Successfully"
              : "Tournament created Successfully"
          );
          getRules(false);
        } else {
          setLoading(false);
          console.log("league err", res);
        }
      } else {
        toast.info("Team number can either be 4, 8 or 16");
      }
    }
  };

  const handleChange = () => {
    setHomeAway(!homeAway);
  };

  console.log("home", data);
  //

  return (
    <Wrapper>
      <Header>
        <HiOutlineArrowNarrowLeft
          className="back"
          onClick={() => getRules(false)}
        />{" "}
        {type.toUpperCase()} RULES
      </Header>
      <Inner>
        <Spacer height="1rem" />
        <h4>Nation</h4>
        <p>Select a nation</p>
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={options[0]}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isRtl={isRtl}
          isSearchable={isSearchable}
          onChange={setSelectedC}
          name="nation"
          options={formatToNameId(country)}
        />
        <Spacer height="1rem" />
        <h4>{type}</h4>
        <p>Select a league</p>
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={league[0]}
          isDisabled={isDisabledT}
          isLoading={isLoading}
          isClearable={isClearable}
          onChange={setSelectedL}
          isRtl={isRtl}
          isSearchable={isSearchable}
          name="leagues"
          options={formatInfo(leagues)}
        />
        <Spacer height="1rem" />
        <h4>Team</h4>
        <p>Select a Team</p>
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={team[0]}
          isDisabled={isDisabledT}
          isLoading={isLoading}
          isClearable={isClearable}
          isRtl={isRtl}
          onChange={setSelectedT}
          isSearchable={isSearchable}
          name="team"
          options={formatInfo(teams)}
        />
        <Spacer height="1rem" />
        <h4>Half Length</h4>
        <p>Select duration of each half</p>
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={times[0]}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onChange={setMTime}
          isClearable={isClearable}
          isRtl={isRtl}
          isSearchable={isSearchable}
          name="time"
          options={times}
        />
        {type === "League" && (
          <>
            <Spacer height="1rem" />
            <h4>Home & Away</h4>
            <p>Toggle on or off Home & Away fixtures</p>
            <Switch
              onChange={handleChange}
              checked={homeAway}
              onColor="#f80033"
            />
          </>
        )}
        <Spacer height="2rem" />
        {/* <CustomButton bg="#3D3D3D" color="#ffffff">
          Invite Friends
        </CustomButton>
        <p>
          Lorem Ipsum theradhofd <br />
          Lorem Ipsum theradhofd Lorem Ipsum theradhofd
        </p> */}
        <PinkButton
          onClick={(e) => {
            if (
              selectedC != null &&
              selectedL != null &&
              selectedT != null &&
              MTime != null
            ) {
              createLeague(e);
            } else {
              toast.info("Kindly Input the missing fields");
            }
          }}
        >
          {loading ? <AppLoader /> : "Complete"}
        </PinkButton>
        <Spacer height="2rem" />
      </Inner>
    </Wrapper>
  );
}

export default LeagueRules;
