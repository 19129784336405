import React from "react";
import styled from "styled-components";
import { AppColors } from "../../config/AppColors";
import { AppFonts } from "../../config/AppFonts";

const FormInput = styled.input`
  display: block;
  border-radius: 2rem;
  padding: 1.3em 1em 1.3em 4em;
  width: 100%;
  box-sizing: border-box;
  background-color: ${AppColors.Trans};
  border: none;
  font-weight: 400;
  color: ${AppColors.OFF_WHITE};

  ::placeholder {
    color: ${AppColors.BLACK};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 360px) {
    padding: 1.3em 1em 1.3em 3em;
  }
`;

function DarkInput({ placeholder, onChange, disabled, value, required }) {
  return (
    <FormInput
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      value={value}
      required={required}
    />
  );
}

export default DarkInput;
