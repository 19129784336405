import React from "react";
import styled from "styled-components";
import TourJoinTab from "../../../../components/tabs/TourJoinTab";
import LeagueCode from "../../Leagues/joinleague/comp/LeagueCode";

const Wrapper = styled.div`
  position: relative;
`;

function JoinTournaments({ fTitle, sTitle }) {
  return (
    <Wrapper>
      <TourJoinTab
        fTitle={fTitle}
        sTitle={sTitle}
        fContent={
          <LeagueCode holder="Enter tournament code" type="Tournament" />
        }
        sContent={<LeagueCode type="Tournament" search={true} />}
      />
    </Wrapper>
  );
}

export default JoinTournaments;
