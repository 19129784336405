import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import userSlice from "../reduxfeatures/user/userSlice";
import teamSlice from "../reduxfeatures/team/teamSlice";

const persistConfig = {
  key: "playRoot",
  version: 1,
  storage,
};

const reducer = combineReducers({
  user: userSlice,
  team: teamSlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
});
