import React from "react";
import styled from "styled-components";
import { AppColors } from "../../config/AppColors";

const Wrapper = styled.div`
  position: relative;
  margin: 0.5rem 1rem;
  padding: 0.5rem 1rem 1rem 1rem;
  min-width: 4.5rem;
  @media (max-width: 760px) {
    margin: 0.5rem 0rem;
    padding: 0.5rem 0.5rem;
    min-width: 4rem;
  }
  div {
    display: grid;
    place-items: center;

    svg {
      fill: ${AppColors.Pinkish};
    }

    p {
      color: ${AppColors.Pinkish};
    }
  }
`;

function ActiveHolder({ children, onClick }) {
  return (
    <Wrapper onClick={onClick}>
      <div>{children}</div>
    </Wrapper>
  );
}

export default ActiveHolder;
