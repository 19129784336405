import React from "react";
import styled from "styled-components";
import { AppColors } from "../../config/AppColors";
import { AppFonts } from "../../config/AppFonts";
import AppLoader from "../loader/AppLoader";

const Button = styled.button`
  position: relative;
  outline: none;
  border: none;
  background-color: ${AppColors.Pinkish};
  height: 3rem;
  font-family: ${AppFonts.regular};
  cursor: pointer;

  color: ${AppColors.WHITE};
  border-radius: 3.5rem;

  &:focus {
    outline: none;
    border: none;
  }
`;

function PinkButton({ children, onClick, isloading, size, float, disabled }) {
  return (
    <Button
      onClick={onClick}
      style={{
        float: float,
        width: size === "half" ? "8rem" : size === "large" ? "12rem" : "10rem",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      disabled={disabled}
    >
      {isloading ? <AppLoader /> : children}
    </Button>
  );
}

export default PinkButton;
