import React from "react";
import styled from "styled-components";
import { AppColors } from "../../config/AppColors";

const Wrapper = styled.div`
  position: relative;
  background: rgb(193 193 193 / 15%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .match-left {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15%;
    color: ${AppColors.WHITE};
    padding: 0.5rem 1rem;
    width: 30%;
  }

  .match-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15%;
    color: ${AppColors.WHITE};
    padding: 0.5rem 1rem;
    width: 30%;
  }

  .table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${AppColors.WHITE};
    padding-left: 0.5rem;
  }

  .sosa {
    position: relative;
    margin: 0;
    width: 20%;
    /* display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${AppColors.Pinkish};  */
    border-bottom: 35px solid ${AppColors.Pinkish};
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    span {
      position: absolute;
      display: flex;
      justify-content: center;
      width: -webkit-fill-available;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 50%);

      @media (max-width: 460px) {
        width: 100%;
      }
    }
  }
`;

function Slab({
  textr,
  textl,
  icon,
  type,
  position,
  home_team_goals,
  away_team_goals,
}) {
  return (
    <Wrapper>
      {type === "match" ? (
        <>
          <div className="match-left">
            {textl}
            {icon}
          </div>
          <p className="sosa">
            <span>
              {home_team_goals} - {away_team_goals}
            </span>
          </p>
          <div className="match-right">
            {icon}
            {textr}
          </div>
        </>
      ) : (
        <div className="table">
          {textr}
          {icon}
        </div>
      )}
    </Wrapper>
  );
}

export default Slab;
