import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MatchUps from "../components/MatchUps";
import { AppColors } from "../../../../config/AppColors";
import axios from "axios";
import { ImTrophy } from "react-icons/im";
import api from "../../../../api/api";
import { getToken } from "../../../../utils";
import { LoadInterface } from "./Fixtures";
import Spacer from "../../../../components/spacer/Spacer";

const Wrapper = styled.div`
  position: relative;

  .none {
    color: ${AppColors.WHITE};
    padding: 0 1.5rem;
    text-align: center;
  }
`;

const MatchWraps = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.2rem;
  margin: 1rem 0;

  .scores {
    padding: 0.25rem 1rem;
    border-radius: 1rem;
    background-color: ${AppColors.Pinkish};
    color: ${AppColors.WHITE};
  }
`;

function LeagueResults({ details }) {
  const [fetching, setFetching] = useState(false);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (details) {
      getGameById();
    }
  }, [details]);

  const getGameById = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setFetching(true);
    const res = await api.getById("tornament", details?.id, "info");
    if (res) {
      setFetching(false);
      setInfo(res?.data?.result);
    } else {
      setFetching(false);
      setInfo(null);
      console.log("tour err", res);
    }
  };
  return (
    <Wrapper>
      {fetching ? (
        <LoadInterface>
          <ImTrophy className="trophy" />
        </LoadInterface>
      ) : info?.length > 0 ? (
        <>
          {info?.map((x, index) => (
            <MatchWraps key={x?.id}>
              <MatchUps text={x?.home_team} type="match" position="home" />
              <p className="scores">
                {x?.home_team_goals} - {x.away_team_goals}
              </p>
              <MatchUps text={x.away_team} type="match" position="away" />
            </MatchWraps>
          ))}
        </>
      ) : (
        <div className="none">
          <Spacer height="2rem" />
          <p>No result yet</p>
        </div>
      )}
    </Wrapper>
  );
}

export default LeagueResults;
