import React from "react";
import styled from "styled-components";
import TourJoinTab from "../../../../components/tabs/TourJoinTab";
import LeagueCode from "./comp/LeagueCode";

const Wrapper = styled.div`
  position: relative;
`;

function JoinLeague({ fTitle, sTitle }) {
  return (
    <Wrapper>
      <TourJoinTab
        fTitle={fTitle}
        sTitle={sTitle}
        fContent={<LeagueCode holder="Enter league code" type="League" />}
        sContent={<LeagueCode search={true} type="League" />}
      />
    </Wrapper>
  );
}

export default JoinLeague;
