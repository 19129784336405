import React, { useState } from "react";
import styled from "styled-components";
import { AppColors } from "../config/AppColors";
import Form from "../components/forms/Form";
import Spacer from "../components/spacer/Spacer";
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { FaRegEnvelope } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import api from "../api/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toast";
import { useDispatch } from "react-redux";

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 5vh 10vw;
  overflow: hidden !important;

  @media (max-width: 768px) {
    padding: 0rem;
    width: -webkit-fill-available;
  }

  h3 {
    color: ${AppColors.WHITE};
    font-size: 1.2rem;
    font-weight: 500;
  }
  p {
    color: ${AppColors.DarkGrey};
  }
`;

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const Flexed = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  .svg-wrap-e {
    padding: 0.5rem;
    background-color: ${AppColors.red};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .svg-wrap-f {
    padding: 0.5rem;
    background-color: ${AppColors.FBblue};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .svg-wrap-t {
    padding: 0.5rem;
    background-color: ${AppColors.TwitterBlue};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .svg-wrap-g {
    padding: 0.5rem;
    background-color: ${AppColors.WHITE};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .e,
  .f,
  .t {
    fill: ${AppColors.WHITE};
  }

  .grey {
    fill: ${AppColors.LIGHTGREY};
    margin: 0 0.8rem;
    font-size: 1.2rem;
  }
`;

function SignUp() {
  const navigate = useNavigate();
  const [payload, setPayload] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const dispatch = useDispatch();

  const getDetails = (data) => {
    setPayload(data);
  };

  const authRequest = async (e) => {
    e.preventDefault();
    if (
      payload.email.length > 5 &&
      payload.cPassword.length > 3 &&
      payload.userName.length > 2 &&
      payload.match
    ) {
      setLoading(true);
      let courier = {
        username: payload.userName,
        email: payload.email,
        password: payload.password,
        password_confirmation: payload.cPassword,
      };
      const res = await api.create("register", courier);
      if (res?.status === true) {
        setValue(res);
        console.log("re", res);
        setLoading(false);
        toast.success("Registration successful");
        navigate("/");
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <Wrapper>
      <div className="inner-wrapper">
        <h3>Sign Up</h3>
        <Spacer height="1rem" />
        <FormWrapper>
          <Form
            type="reg"
            getDetails={getDetails}
            loading={loading}
            request={(e) => authRequest(e)}
          />
        </FormWrapper>
        <Spacer height="1rem" />
        <p>Or sign up with</p>
        <Spacer height="0.5rem" />
        <Flexed>
          <div className="svg-wrap-e">
            <FaRegEnvelope className="e" />
          </div>
          <div className="svg-wrap-f">
            <FaFacebookF className="f" />
          </div>
          <div className="svg-wrap-t">
            <BsTwitter className="t" />
          </div>
          <div className="svg-wrap-g">
            <FcGoogle className="g" />
          </div>
        </Flexed>
        <Spacer height="1.5rem" />
        <Flexed>
          <FaFacebookF className="grey" />
          <FaInstagram className="grey" />
          <BsTwitter className="grey" />
          <FaYoutube className="grey" />
        </Flexed>
        <Spacer height="1rem" />
      </div>
    </Wrapper>
  );
}

export default SignUp;
