import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MatchUps from "../components/MatchUps";
import { AppColors } from "../../../../config/AppColors";
import axios from "axios";
import { getToken } from "../../../../utils";
import api from "../../../../api/api";
import { ImTrophy } from "react-icons/im";
import Spacer from "../../../../components/spacer/Spacer";

const Wrapper = styled.div`
  position: relative;

  .none {
    color: ${AppColors.WHITE};
    padding: 0 1.5rem;
    text-align: center;
  }

  .announce {
    color: #fff;
    margin-top: 1rem;
    display: grid;
    place-items: center;
    svg {
      fill: ${AppColors.WHITE};
      font-size: 2.5rem;
    }
  }
`;

const MatchWraps = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;

  .scores {
    padding: 0.25rem 1rem;
    border-radius: 1rem;
    background-color: ${AppColors.Pinkish};
    color: ${AppColors.WHITE};
  }
`;

export const LoadInterface = styled.div`
  display: grid;
  position: relative;
  place-items: center;
  z-index: 0;
  height: 30vh;

  svg {
    fill: ${AppColors.WHITE};
    font-size: 5rem;
    animation: breathing 2s infinite forwards;

    @keyframes breathing {
      0% {
        transform: scale(0.9);
      }
      50% {
        transform: scale(1);
      }
      100% {
        transform: scale(0.9);
      }
    }

    &:focus {
      outline: none;
      border: none;
    }
    order: none;
  }
`;

function Fixtures({ details }) {
  const [fetching, setFetching] = useState(false);
  const [info, setInfo] = useState([]);
  const [general, setGeneral] = useState([]);
  const [winner, setWinner] = useState([]);

  useEffect(() => {
    if (details) {
      getGameById();
    }
  }, [details]);

  useEffect(() => {
    if (sortedData?.length > 0) {
      getWinner();
    }
  }, [general]);

  const getGameById = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setFetching(true);
    const res = await api.getById("tornament", details?.id, "info");
    if (res) {
      setFetching(false);
      setGeneral(res?.data?.table);
      setInfo(res?.data?.fixture);
    } else {
      setFetching(false);
      setInfo(null);
      console.log("tour err", res);
    }
  };

  const getWinner = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setFetching(true);
    const res = await api.getById("tornament", details?.id, "winner");
    if (res) {
      setFetching(false);
      console.log(res);
      setWinner(res?.data.winner);
    } else {
      setFetching(false);
      setInfo(null);
      console.log("tour err", res);
    }
  };

  const sortedData =
    general?.length > 0
      ? general?.slice().sort((a, b) => b.points - a.points)
      : general;
  console.log("win", winner);

  return (
    <Wrapper>
      {fetching ? (
        <LoadInterface>
          <ImTrophy className="trophy" />
        </LoadInterface>
      ) : info?.length > 0 ? (
        <>
          {info?.map((x, index) => (
            <MatchWraps key={x?.id}>
              <MatchUps text={x?.home_team} type="match" position="home" />
              {x.is_completed === 0 ? (
                <p className="scores">
                  {x?.home_team_goals} - {x?.away_team_goals}
                </p>
              ) : (
                <p className="scores">vs</p>
              )}
              <MatchUps text={x?.away_team} type="match" position="away" />
            </MatchWraps>
          ))}
        </>
      ) : sortedData.length > 0 ? (
        <div className="announce">
          <ImTrophy className="trophy" />
          <p>WINNER - {winner?.team}</p>
        </div>
      ) : (
        <div className="none">
          <Spacer height="2rem" />
          <p>No fixtures yet</p>
        </div>
      )}
    </Wrapper>
  );
}

export default Fixtures;
