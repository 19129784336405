import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BsChevronRight } from "react-icons/bs";
import { AppColors } from "../../config/AppColors";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toast";
import { useDispatch } from "react-redux";
import { clearTeam } from "../../reduxfeatures/team/teamSlice";
import { clearUser } from "../../reduxfeatures/user/userSlice";

const Wrapper = styled.div`
  position: relative;
`;
const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(193 193 193 / 15%);

  cursor: pointer;
  padding: 0 1rem;

  &:hover {
    background: rgb(160 159 159 / 5%);
  }

  p {
    color: ${AppColors.WHITE};
  }
  svg {
    fill: ${AppColors.WHITE};
  }
`;

function SettingOptions({ getHit, getOption }) {
  const navigate = useNavigate();
  const [option, setOption] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    getOption(option);
    if (option !== "") {
      getHit(true);
    }
  }, [option]);

  return (
    <Wrapper>
      <Flex
        onClick={() => {
          setOption("pref");
        }}
      >
        <p>Preferences</p>
        <BsChevronRight />
      </Flex>
      <Flex
        onClick={() => {
          setOption("security");
        }}
      >
        <p>Security</p>
        <BsChevronRight />
      </Flex>
      <Flex
        onClick={() => {
          setOption("delete");
        }}
      >
        <p>Delete Account</p>
        <BsChevronRight />
      </Flex>
      <Flex
        onClick={() => {
          localStorage.clear();
          toast.success("User logged out");
          dispatch(clearTeam());
          dispatch(clearUser());
          navigate("/");
        }}
      >
        <p>Log out</p>
      </Flex>
    </Wrapper>
  );
}

export default SettingOptions;
