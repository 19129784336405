import React, { useEffect, useState } from "react";
import { BsTwitter } from "react-icons/bs";
import {
  FaEye,
  FaEyeSlash,
  FaFacebookF,
  FaInstagram,
  FaLock,
  FaRegEnvelope,
  FaYoutube,
} from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Form from "../components/forms/Form";
import Logo from "../components/Logo/Logo";
import Spacer from "../components/spacer/Spacer";
import { AppColors } from "../config/AppColors";
import api from "../api/api";
import { toast } from "react-toast";
import Input from "../components/Inputs/Input";
import DefaultButton from "../components/buttons/DefaultButton";

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 5vh 10vw;

  @media (max-width: 768px) {
    padding: 0rem;
    width: -webkit-fill-available;
    overflow: hidden !important;
  }

  h1 {
    color: ${AppColors.WHITE};
    padding: 1rem 0;
  }

  .sign-route {
    color: ${AppColors.WHITE};

    span {
      color: ${AppColors.red};
      cursor: pointer;
    }
  }
  .connect {
    color: ${AppColors.DarkGrey};
  }
`;

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  .otp-wrap {
    position: relative;
    background: #fff;
    padding: 3em;
    border-radius: 2rem;
  }
`;

export const Flexed = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  .svg-wrap-e {
    padding: 0.5rem;
    background-color: ${AppColors.red};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .svg-wrap-f {
    padding: 0.5rem;
    background-color: ${AppColors.FBblue};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .svg-wrap-t {
    padding: 0.5rem;
    background-color: ${AppColors.TwitterBlue};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .svg-wrap-g {
    padding: 0.5rem;
    background-color: ${AppColors.WHITE};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .e,
  .f,
  .t {
    fill: ${AppColors.WHITE};
  }

  .grey {
    fill: ${AppColors.LIGHTGREY};
    margin: 0 0.8rem;
    font-size: 1.2rem;
  }
`;

export const WrapInput = styled.div`
  position: relative;
  padding: 0.3rem 0;
  svg {
    position: absolute;
    left: 1.8rem;
    top: 1.3rem;
    cursor: pointer;
    fill: ${AppColors.LIGHTGREY};

    @media (max-width: 500px) {
      left: 1.5rem;
      top: 1.1rem;
    }
  }

  .lock {
    position: absolute;
    left: 1.8rem;
    top: 1.3rem;
    cursor: pointer;
    fill: ${AppColors.LIGHTGREY};

    @media (max-width: 500px) {
      left: 1.5rem;
      top: 1.1rem;
    }
  }

  .eye {
    position: absolute;
    top: 1.3rem;
    left: 85%;
    cursor: pointer;
    fill: ${AppColors.LIGHTGREY};

    @media (max-width: 500px) {
      left: 85%;
      top: 1.1rem;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [hide, setHide] = useState(true);
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [match, setMatch] = useState(true);

  useEffect(() => {
    setEmail(location.search.slice(1));
  }, []);

  const resetPass = async (e) => {
    e.preventDefault();
    if (email.length > 4 && match) {
      setLoading(true);
      let courier = {
        email: email,
        password: password,
        password_confirmation: cPassword,
      };
      const res = await api.create("resetPassword", courier);
      if (res?.status === true) {
        setLoading(false);
        toast.success(res.message);
        navigate("/");
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <Wrapper>
      <div className="inner-wrapper">
        <Logo />
        <FormWrapper>
          <div className="otp-wrap">
            <h2 style={{ marginTop: 0 }}>Reset Password</h2>
            <WrapInput>
              <Input
                placeholder="New Password"
                type={show ? "password" : "text"}
                required={true}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FaLock className="lock" />
              {show ? (
                <FaEye className="eye" onClick={() => setShow(!show)} />
              ) : (
                <FaEyeSlash className="eye" onClick={() => setShow(!show)} />
              )}
            </WrapInput>
            <WrapInput>
              <Input
                placeholder="Confirm Password"
                type={hide ? "password" : "text"}
                required={true}
                disabled={password?.length > 0 ? false : true}
                onChange={(e) => {
                  setCPassword(e.target.value);
                  if (e.target.value != password) {
                    setMatch(false);
                  } else {
                    setMatch(true);
                  }
                }}
                style={{
                  color: match ? `${AppColors.BLACK}` : `${AppColors.red}`,
                }}
              />
              <FaLock className="lock" />
              {hide ? (
                <FaEye className="eye" onClick={() => setHide(!hide)} />
              ) : (
                <FaEyeSlash className="eye" onClick={() => setHide(!hide)} />
              )}
            </WrapInput>
            <Spacer height="1.5rem" />
            <DefaultButton onClick={resetPass} isloading={loading}>
              Reset
            </DefaultButton>
          </div>
        </FormWrapper>
        <Spacer height="1.5rem" />
        <Flexed>
          <div className="svg-wrap-e">
            <FaRegEnvelope className="e" />
          </div>
          <div className="svg-wrap-f">
            <FaFacebookF className="f" />
          </div>
          <div className="svg-wrap-t">
            <BsTwitter className="t" />
          </div>
          <div className="svg-wrap-g">
            <FcGoogle className="g" />
          </div>
        </Flexed>
        <Spacer height="2rem" />
        <Flexed>
          <FaFacebookF className="grey" />
          <FaInstagram className="grey" />
          <BsTwitter className="grey" />
          <FaYoutube className="grey" />
        </Flexed>
      </div>
    </Wrapper>
  );
}

export default ResetPassword;
