import React from "react";
import styled from "styled-components";
import { AppColors } from "../../config/AppColors";
import { AppFonts } from "../../config/AppFonts";
import AppLoader from "../loader/AppLoader";
import ColoredLoader from "../loader/ColoredLoader";

const Button = styled.button`
  outline: none;
  border: none;
  height: 3rem;
  font-family: ${AppFonts.regular};
  border-radius: 3.5rem;
  cursor: pointer;

  &:focus {
    outline: none;
    border: none;
  }
`;

function CustomButton({ children, onClick, isloading, size, bg, color }) {
  return (
    <Button
      onClick={onClick}
      style={{
        width: size === "half" ? "8rem" : "10rem",
        backgroundColor: bg,
        color: color,
      }}
    >
      {isloading ? <ColoredLoader bg="#f80033" /> : children}
    </Button>
  );
}

export default CustomButton;
