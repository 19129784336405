import React from "react";
import styled from "styled-components";
import Brackets from "../bracket/Bracket";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  .b-container {
    width: 100%;
  }
`;

function TournamentResult({ details }) {
  return (
    <Wrapper>
      <div className="b-container">
        <Brackets details={details} />{" "}
      </div>
    </Wrapper>
  );
}

export default TournamentResult;
