import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Logo from "../components/Logo/Logo";
import Spacer from "../components/spacer/Spacer";
import { AppColors } from "../config/AppColors";
import Selectors from "../components/selectors/Selectors";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

const Wrapper = styled.div`
  position: relative;
  padding: 0rem;
  width: -webkit-fill-available;

  @media (min-width: 768px) {
    display: grid;
    place-items: center;
    padding: 2rem 2rem 0 2rem;
  }
  h2 {
    color: white;
  }

  .holding {
    height: 40vh;
    position: relative;
    display: grid;
    place-items: center;
    div {
      margin: 0 auto;
    }
  }

  .footing {
    max-width: 1000px;
    overflow-x: hidden;
    min-width: 380px;

    @media (max-width: 768px) {
      padding: 1vh 0;
    }
  }

  .b-holding {
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 4%;
    left: 50%;
    transform: translate(-50%, 50%);
    background: #1c2127;
    width: 100%;

    @media (max-width: 768px) {
      bottom: 5%;
    }
  }
`;

const AppWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
`;

const NoticeWrapper = styled.div`
  position: relative;

  .back {
    position: absolute;
    left: 1rem;
    color: #fff;
    cursor: pointer;
  }

  h2,
  h3,
  h4,
  p,
  ul {
    color: ${AppColors.WHITE};
  }

  li {
    margin: 0.3rem 0;
    line-height: 1.4rem;
  }

  h3 {
    font-size: 1.3rem !important;
    font-weight: 500;
    text-align: center;
  }
  h4 {
    font-size: 1.1rem;
    text-align: center;
    font-weight: 400;
  }
  p {
    font-weight: 100;
    line-height: 1.4rem;
    @media (max-width: 768px) {
      font-weight: 100;
    }
  }
`;

const Curved = styled.div`
  position: relative;
  display: flex;
  background: hsl(224deg 29.41% 10% / 55%);
  justify-content: center;
  padding: 1rem 1.5rem 2rem 1.5rem;
  max-width: 550px;

  @media (max-width: 768px) {
    margin: 0 0.5rem;
    max-width: unset;
  }

  .hold {
    width: 100%;
    @media (min-width: 768px) {
      margin-top: 0rem;
    }
  }

  hr {
    color: ${AppColors.DarkGrey};
  }
`;

function Welcome() {
  const navigate = useNavigate();
  const token = localStorage.getItem("PRivalsToken");

  return (
    <Wrapper>
      <div className="inner-wrapper">
        <Logo />
        <Spacer height="0.2rem" />
        <Curved>
          <div className="hold">
            <NoticeWrapper>
              <HiOutlineArrowNarrowLeft
                className="back"
                onClick={() => {
                  navigate(-1);
                }}
              />
              <h3>Welcome to Play Rivalz</h3>
              <h4>
                Make your football games matter with Play Rivalz. Taking your
                rivalries to the next level.
              </h4>
              <p>
                The Play Rivalz platform is built on the belief that gaming
                football matches mean more! No clutter and no mess, we built
                this platform to allow friends and online communities to engage
                in high stakes online or offline gaming football leagues and
                tournaments where more than just pride can be on the line. In a
                few simple steps, you can set up and participate in your own
                gaming football competition, all in one easy to use platform.
              </p>
              <ul>
                <li>
                  Dedicated platform (mobile optimised website) for gaming
                  football competitions.
                </li>
                <li>
                  Players can create/join private leagues/tournaments with their
                  friends and/or online community.
                </li>
                <li>
                  Players can simply stay on track with fixtures, league tables
                  and tournaments all in one platform.
                </li>
                <li>
                  Simple and user-friendly platform to keep the casual gaming
                  football players engaged and connected.
                </li>
              </ul>
            </NoticeWrapper>
          </div>
        </Curved>
        <Spacer height="10rem" />
      </div>
      <div className="b-holding">
        <div className="footing">
          <Selectors />
          {/* <Flexed>
            <FaFacebookF className="grey" />
            <FaInstagram className="grey" />
            <BsTwitter className="grey" />
            <FaYoutube className="grey" />
          </Flexed> */}
        </div>
      </div>
    </Wrapper>
  );
}

export default Welcome;
