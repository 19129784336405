import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ArrowNav from "../../../../components/Navigation/ArrowNav";
import Fixtures from "../../Leagues/paths/Fixtures";
import TournamentResult from "./TournamentResult";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import api from "../../../../api/api";
import { getToken } from "../../../../utils";
import ReactLoading from "react-loading";
import { AppColors } from "../../../../config/AppColors";
import TournamentDetails from "./TournamentDetails";
import SubmitResults from "./SubmitResults";
import TournamentTeams from "./TournamentTeams";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Wrapper = styled.div`
  position: relative;

  .holding {
    height: 40vh;
    position: relative;
    display: grid;
    place-items: center;
    div {
      margin: 0 auto;
    }
  }
  .flex-container {
    width: 300px;
    margin: 0 auto 1rem auto;

    .react-multiple-carousel__arrow {
      box-shadow: 0px 7.27273px 26.1818px #f44;
    }
    .react-multiple-carousel__arrow--left {
      margin-left: -0.5rem !important;
    }
    .react-multiple-carousel__arrow--right {
      right: 1rem !important;
    }
    .react-multi-carousel-item {
      width: 300px !important;
    }
    .react-multi-carousel-track {
      gap: 0rem;
    }
    .react-multi-carousel-item--active {
      text-align: center;
      color: ${AppColors.Pinkish};
      font-size: 1.2rem;
      font-weight: 700;
    }
  }
`;

function TourPaths({ getCurrent }) {
  const [elements, setElements] = useState([
    "TOURNAMENT DETAILS",
    "TEAMS",
    "FIXTURES",
    "RESULTS",
    "STANDINGS",
    "SUBMIT RESULTS",
  ]);
  const [index, setIndex] = useState(0);
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [ost, setOst] = useState(0);

  useEffect(() => {
    getTours();
  }, []);

  const handleRemoveElement = () => {
    const list = [...elements];
    console.log(list.slice(0, list.length - 1));
    setElements(list.slice(0, list.length - 1));
  };

  useEffect(() => {
    if (check) {
      handleRemoveElement();
    }
  }, [check]);

  const getTours = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setLoading(true);
    const res = await api.getAll("tornament");
    if (res) {
      setLoading(false);
      const filtered = res?.data?.my_events?.filter(
        (x) => x.game_type_id === 2
      );
      console.log(res);
      if (filtered?.length < 1) {
        setCheck(true);
      }
      setTours(filtered);
    } else {
      setLoading(false);
      setTours([]);
      console.log("tour err", res);
    }
  };

  const updateDisplay = () => {
    const currentElement = elements[index];
    return currentElement;
  };

  const RightLoopButtonClick = () => {
    setIndex((index + 1) % elements.length);
  };

  const LeftLoopButtonClick = () => {
    setIndex((index + (elements.length - 1)) % elements.length);
  };

  return (
    <Wrapper>
      {!loading ? (
        <>
          <div className="flex-container">
            <Carousel
              autoPlay={false}
              responsive={responsive}
              arrows={true}
              partialVisible={true}
              beforeChange={(nextSlide, { currentSlide, onMove }) => {
                setOst(nextSlide);
              }}
            >
              {tours?.map((x) => (
                <p>{x.name}</p>
              ))}
            </Carousel>
          </div>
          <ArrowNav
            onRightClick={() => RightLoopButtonClick()}
            onLeftClick={() => LeftLoopButtonClick()}
          >
            {updateDisplay()}
          </ArrowNav>
          {index === 0 && (
            <TournamentDetails
              details={tours[ost]}
              getCurrent={getCurrent}
              refresh={getTours}
            />
          )}
          {index === 1 && <TournamentTeams details={tours[ost]} />}
          {index === 2 && <Fixtures details={tours[ost]} />}
          {index === 3 && <TournamentResult details={tours[ost]} />}
          {index === 4 && <TournamentResult details={tours[ost]} />}
          {index === 5 && tours?.length > 0 && (
            <SubmitResults details={tours[ost]} type="Tournament" />
          )}
        </>
      ) : (
        <div className="holding">
          <ReactLoading
            type={"balls"}
            color={"#f80033"}
            height={"20%"}
            width={"20%"}
          />
        </div>
      )}
    </Wrapper>
  );
}

export default TourPaths;
