import React, { useEffect, useState } from "react";
import { BsFillBellFill } from "react-icons/bs";
import styled from "styled-components";
import Selectors from "../../../components/selectors/Selectors";
import Spacer from "../../../components/spacer/Spacer";
import { toast } from "react-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../utils";
import api from "../../../api/api";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import Logo from "../../../components/Logo/Logo";
import { AppColors } from "../../../config/AppColors";
import HomeTab from "../../../components/tabs/HomeTab";
import CreateLeague from "../Leagues/createleague/CreateLeague";
import Ranking, { MatchWraps } from "./Ranking";
import Slab from "../../../components/slab/Slab";
import ReactLoading from "react-loading";
import Switch from "react-switch";
import HomeLeagueRules from "../Leagues/createleague/HomeLeagueRules";

const Wrapper = styled.div`
  position: relative;
  padding: 0rem;
  width: -webkit-fill-available;

  @media (min-width: 768px) {
    display: grid;
    place-items: center;
    padding: 2rem 2rem 0 2rem;
  }
  h2 {
    color: white;
  }

  .holding {
    height: 40vh;
    position: relative;
    display: grid;
    place-items: center;
    div {
      margin: 0 auto;
    }
  }

  .footing {
    max-width: 1000px;
    overflow-x: hidden;
    min-width: 380px;

    @media (max-width: 768px) {
      padding: 1vh 0;
    }
  }

  .b-holding {
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 4%;
    left: 50%;
    transform: translate(-50%, 50%);
    background: #1c2127;
    width: 100%;

    @media (max-width: 768px) {
      bottom: 5%;
    }
  }
`;

const AppWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
`;

const NoticeWrapper = styled.div`
  position: relative;

  .main-home {
    position: relative;

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2,
      p {
        color: ${AppColors.WHITE};
      }

      h2 {
        font-size: 1.2rem !important;
        font-weight: 500;
      }

      p {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 0.9rem;
        font-weight: 100;
        @media (max-width: 768px) {
          font-size: 0.6rem;
          font-weight: 100;
        }
      }
    }
  }
`;

const News = styled.div`
  position: relative;

  .news-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
  }
  .papers {
    display: grid;
    justify-content: center;
    width: 33%;
    cursor: pointer;
    height: 140px;

    &:hover {
      scale: 0.95;
    }
    p {
      display: block;
      color: ${AppColors.WHITE};
      font-size: 0.9rem;
      text-align: center;
      @media (max-width: 768px) {
        font-size: 0.6rem;
        font-weight: 100;
      }
    }
  }
  .papers img {
    max-width: 150px;
    min-width: 100px;
    height: 4.5rem;
    border-radius: 0.3rem;
    @media (max-width: 768px) {
      display: block;
      width: 100%;
    }
  }
`;

const LandT = styled.div`
  position: relative;
  margin-top: 1.5rem;

  h2,
  p {
    color: ${AppColors.WHITE};
  }

  h2 {
    font-size: 1.2rem !important;
    font-weight: 500;
  }
`;

const Upcoming = styled.div`
  position: relative;
  margin-top: 1.5rem;

  h2,
  p {
    color: ${AppColors.WHITE};
  }

  h2 {
    font-size: 1.2rem !important;
    font-weight: 500;
  }

  .view {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 0.5rem;
    font-size: 0.9rem;
    @media (max-width: 768px) {
      font-size: 0.6rem;
      font-weight: 100;
    }
    span {
      cursor: pointer;
    }
  }

  .holds {
    height: 20vh;
    position: relative;
    display: grid;
    place-items: center;
    div {
      margin: 0 auto;
    }
  }
`;

const Notifications = styled.div`
  position: relative;
  margin-top: 1.5rem;

  h2,
  p {
    color: ${AppColors.WHITE};
  }

  h2 {
    font-size: 1.2rem !important;
    font-weight: 500;
  }

  p {
    font-size: 0.9rem;
    font-weight: 100;
    @media (max-width: 768px) {
      font-size: 0.7rem;
    }
  }
`;

const Curved = styled.div`
  position: relative;
  display: flex;
  background: hsl(224deg 29.41% 10% / 55%);
  justify-content: center;
  padding: 1rem 1.5rem 2rem 1.5rem;
  max-width: 550px;

  @media (max-width: 768px) {
    margin: 0 0.5rem;
    max-width: unset;
  }

  .hold {
    width: 100%;
    @media (min-width: 768px) {
      margin-top: 0rem;
    }
  }

  hr {
    color: ${AppColors.DarkGrey};
  }
`;

const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0;
  margin: 0.5rem 0;

  .nots {
    path {
      fill: ${AppColors.WHITE};
    }
  }

  .ems {
    fill: ${AppColors.WHITE};
  }

  p {
    font-size: 1rem;
    font-weight: 500;
  }
`;

const HomeWrapper = styled.div`
  position: relative;
  margin-top: 1rem;
`;

function Home() {
  const navigate = useNavigate();
  const token = localStorage.getItem("PRivalsToken");
  const [loading, setLoading] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [upcomingData, setUpcomingData] = useState([]);
  const [rules, setRules] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [country, setCountry] = useState(null);
  const [createData, setCreateData] = useState(null);
  const [push, setPush] = useState(false);
  const [ePush, setEPush] = useState(false);

  useEffect(() => {
    if (!token) {
      toast.error("Access Denied, Kindly Login to get access");
      navigate("/");
    } else {
      getNotification();
    }
  }, [currentTab]);

  const getRules = (data) => {
    setRules(data);
  };

  const getCurrent = (data) => {
    setCurrentTab(data);
    console.log("cusr", data);
  };

  const getCountry = (data) => {
    setCountry(data);
  };

  const getData = (data) => {
    setCreateData(data);
  };

  const getNotification = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setLoading(true);
    const res = await api.getAll("tornament/dashboard");
    if (res) {
      setLoading(false);
      console.log("ada", res);
      const uFiltered = res?.data.fixture.filter(
        (x) => x.is_completed === 0 && x.game_type_id === 1
      );
      const torFiltered = res?.data.fixture.filter(
        (x) => x.is_completed === 0 && x.game_type_id === 2
      );
      if (currentTab === 0) {
        setUpcomingData(uFiltered);
      } else {
        setUpcomingData(torFiltered);
      }
    } else {
      setLoading(false);
      setResultData([]);
      setUpcomingData([]);
      console.log("team err", res);
    }
  };

  const handleNChange = () => {
    setPush(!push);
  };
  const handleEChange = () => {
    setEPush(!ePush);
  };
  console.log("up", upcomingData);

  return (
    <Wrapper>
      <div className="inner-wrapper">
        <Logo />
        <Spacer height="0.2rem" />
        <Curved>
          <div className="hold">
            <NoticeWrapper>
              <div className="main-home">
                <News>
                  <div className="head">
                    <h2>News & Updates</h2>
                  </div>
                  <div className="news-body">
                    <div
                      className="papers"
                      onClick={() => navigate("/home/welcome")}
                    >
                      <img
                        className="p-logo"
                        src="/images/half-logo.png"
                        alt="first"
                      />
                      <p>Welcome to Play Rivalz</p>
                    </div>
                    <div
                      className="papers"
                      onClick={() => navigate("/home/how-to-use")}
                    >
                      <img src="/images/New2.jpeg" alt="second" />
                      <p>How to Use</p>
                    </div>
                    <div className="papers">
                      <img src="/images/news3.jpg" alt="third" />
                      <p>Latest Updates (coming soon)</p>
                    </div>
                  </div>
                </News>
                <hr />
                <LandT>
                  <h2>Leagues & Tournaments</h2>
                  <HomeWrapper>
                    <HomeTab
                      current={currentTab}
                      fTitle="Leagues"
                      sTitle="Tournaments"
                      tTitle="Create League"
                      fContent={
                        <Ranking type="league" getCurrent={getCurrent} />
                      }
                      sContent={
                        <Ranking type="tournament" getCurrent={getCurrent} />
                      }
                      tContent={
                        !rules ? (
                          <CreateLeague
                            getRules={getRules}
                            type="League"
                            getCurrent={getCurrent}
                            getCountry={getCountry}
                            getData={getData}
                          />
                        ) : (
                          <HomeLeagueRules
                            type="League"
                            country={country}
                            getRules={getRules}
                            data={createData}
                          />
                        )
                      }
                    />
                  </HomeWrapper>
                </LandT>
                <Spacer height="0.5rem" />
                <hr />
                {currentTab != 2 && (
                  <>
                    <Upcoming>
                      <h2>Upcoming Fixtures</h2>
                      {loading ? (
                        <div className="holds">
                          <ReactLoading
                            type={"balls"}
                            color={"#f80033"}
                            height={"20%"}
                            width={"20%"}
                          />
                        </div>
                      ) : (
                        <>
                          {upcomingData?.length > 0 ? (
                            <>
                              {upcomingData?.slice(0, 3).map((x, index) => (
                                <MatchWraps key={x?.id}>
                                  <Slab
                                    textl={x?.home_team}
                                    textr={x.away_team}
                                    type="match"
                                    home_team_goals={x?.home_team_goals}
                                    away_team_goals={x.away_team_goals}
                                  />
                                </MatchWraps>
                              ))}
                              <p className="view">
                                <span
                                  onClick={() => {
                                    if (currentTab === 0) {
                                      navigate("/leagues");
                                    } else navigate("/tournaments");
                                  }}
                                >
                                  View all <HiOutlineArrowNarrowRight />
                                </span>
                              </p>
                            </>
                          ) : (
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "0.85rem",
                                marginTop: "1.5rem",
                              }}
                            >
                              No upcoming fixtures
                            </p>
                          )}
                        </>
                      )}
                    </Upcoming>
                    <Spacer height="0.5rem" />
                    <hr />
                  </>
                )}
                <Notifications>
                  <h2>Notifications</h2>
                  <p>Setup notifications here</p>
                  <Flex>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <BsFillBellFill className="nots" />
                      <p>Push Notifications</p>
                    </div>
                    <Switch
                      onChange={handleNChange}
                      checked={push}
                      onColor="#f80033"
                    />
                  </Flex>
                  <Flex>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <MdEmail className="ems" />
                      <p>Emails</p>
                    </div>
                    <Switch
                      onChange={handleEChange}
                      checked={ePush}
                      onColor="#f80033"
                    />
                  </Flex>
                </Notifications>
              </div>
              {/* <NotificationTab
                //  mTitle={<Notices text="Message" count={19} />}
                rTitle={<Notices text="Results" count={resultData?.length} />}
                uTitle={
                  <Notices
                    text="Upcoming fixtures"
                    count={upcomingData?.length}
                  />
                }
                //  iTitle={<Notices text="Invitation" count={2} />}
                // mContent={<GroupMessages />}
                rContent={
                  loading ? (
                    <div className="holding">
                      <ReactLoading
                        type={"balls"}
                        color={"#f80033"}
                        height={"20%"}
                        width={"20%"}
                      />
                    </div>
                  ) : (
                    <Results data={resultData} />
                  )
                }
                uContent={
                  loading ? (
                    <div className="holding">
                      <ReactLoading
                        type={"balls"}
                        color={"#f80033"}
                        height={"20%"}
                        width={"20%"}
                      />
                    </div>
                  ) : (
                    <UpcomingFixtures data={upcomingData} />
                  )
                }
                // iContent={<InvitationGroup />}
              /> */}
            </NoticeWrapper>
          </div>
        </Curved>
        <Spacer height="10rem" />
      </div>
      <div className="b-holding">
        <div className="footing">
          <Selectors />
          {/* <Flexed>
            <FaFacebookF className="grey" />
            <FaInstagram className="grey" />
            <BsTwitter className="grey" />
            <FaYoutube className="grey" />
          </Flexed> */}
        </div>
      </div>
    </Wrapper>
  );
}

export default Home;
