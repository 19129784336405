import React from "react";
import styled from "styled-components";
import MiniTab from "../../../../components/tabs/MiniTab";
import OtherTourPath from "./OtherTourPath";
import TourPaths from "./TourPaths";

const Wrapper = styled.div`
  position: relative;
`;

function AllTournaments({ fTitle, sTitle, getCurrent }) {
  return (
    <Wrapper>
      <MiniTab
        fTitle="My tournaments"
        sTitle="Other tournaments"
        fContent={<TourPaths getCurrent={getCurrent} />}
        sContent={<OtherTourPath />}
      />
    </Wrapper>
  );
}

export default AllTournaments;
