import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { AppColors } from "../../config/AppColors";
import Spacer from "../spacer/Spacer";

const Wrapper = styled.div`
  position: relative;

  .react-tabs__tab-list {
    border-bottom: none;
    background-color: ${AppColors.tabsGrey};
    margin: 0rem 0rem 1rem 0;
    border-radius: 2rem;
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .react-tabs__tab {
    bottom: 0px;
    padding: 1.5rem 1.5rem;
    min-width: 5rem;
    text-align: center;
    color: ${AppColors.WHITE};
    font-size: 0.8rem;
  }
  .react-tabs__tab--selected {
    background-color: ${AppColors.Pinkish};
    border: none;
    border-radius: 2rem;
  }

  .react-tabs__tab:focus:after {
    background-color: transparent;
  }
`;

function TabSwitch({
  fTitle,
  sTitle,
  tTitle,
  fContent,
  sContent,
  tContent,
  league,
  current,
}) {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    setTabIndex(current);
    console.log("curr", current);
  }, [current]);
  return (
    <Wrapper>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>{fTitle}</Tab>
          <Tab>{sTitle}</Tab>
          {league && <Tab>{tTitle}</Tab>}
        </TabList>
        <TabPanel>{fContent}</TabPanel>
        <TabPanel>{sContent}</TabPanel>
        {league && <TabPanel>{tContent}</TabPanel>}
      </Tabs>
    </Wrapper>
  );
}

export default TabSwitch;
