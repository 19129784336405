import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DarkInput from "../../../../components/Inputs/DarkInput";
import Spacer from "../../../../components/spacer/Spacer";
import { FaUnlock } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import PinkButton from "../../../../components/buttons/PinkButton";
import { AppColors } from "../../../../config/AppColors";
import axios from "axios";
import { getToken } from "../../../../utils";
import api from "../../../../api/api";
import ReactLoading from "react-loading";
import Select from "react-select";

const Wrapper = styled.div`
  position: relative;
  padding: 0 2.6rem;

  h4 {
    color: ${AppColors.WHITE};
    font-size: 0.8rem !important;
    font-weight: 500;
    margin: 0 0 8px 0;
  }

  p {
    color: ${AppColors.DarkGrey};
    padding: 8px 0;
    font-size: 0.8rem;
    margin: 0;
  }

  .holding {
    height: 40vh;
    position: relative;
    display: grid;
    place-items: center;
    div {
      margin: 0 auto;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .selector .select__control--is-focused.css-t3ipsp-control {
    background-color: ${AppColors.Trans};
    border-color: ${AppColors.Trans};
    border-radius: 2rem;
    padding: 0.3em 1em 0.3em 1em;
  }

  .css-13cymwt-control {
    background-color: ${AppColors.Trans};
    border-color: ${AppColors.Trans};
    border-radius: 2rem;
    padding: 0.3em 1em 0.3em 1em;
  }

  .css-1dimb5e-singleValue {
    color: ${AppColors.WHITE} !important;
  }

  /* .select__clear-indicator,
  .css-1u9des2-indicatorSeparator,
  .select__indicator-separator {
    display: none;
  } */
`;

const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.3rem;

  .mini {
    margin: 0 0 8px 0;
  }
`;

const BtnDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`;

const DarkFlex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: min-content;
  gap: 0.1rem;
  background-color: ${AppColors.Trans};
  border-radius: 2rem;

  .active {
    fill: ${AppColors.WHITE};
    background-color: ${AppColors.Pinkish};
    padding: 1rem;
    border-radius: 50%;
  }
  .inactive {
    padding: 1rem;
    fill: ${AppColors.WHITE};
    cursor: pointer;
  }
`;

const NumInput = styled.input`
  display: block;
  border-radius: 2rem;
  padding: 1.3em 1em 1.3em 4em;
  width: 100%;
  box-sizing: border-box;
  background-color: ${AppColors.Trans};
  color: ${AppColors.OFF_WHITE};

  border: none;
  font-weight: 700;

  &:focus {
    outline: none;
  }

  @media (max-width: 360px) {
    padding: 1.3em 1em 1.3em 3em;
  }
`;

function CreateLeague({ getRules, type, getCurrent, getCountry, getData }) {
  const [lock, setLock] = useState(true);
  const [leagueName, setLeagueName] = useState("");
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [getting, setGetting] = useState(false);
  const [team, setTeam] = useState(null);
  const [isRtl, setIsRtl] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [country, setCountry] = useState(null);
  const players = [
    { value: "", label: "Select Number of Players" },
    { value: 4, label: 4 },
    { value: 8, label: 8 },
    { value: 16, label: 16 },
  ];
  let count = 0;

  useEffect(() => {
    listCountry();
    getCurrent(2);
  }, []);

  const listCountry = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setGetting(true);
    const res = await api.getAll("country");
    if (res) {
      setGetting(false);
      console.log("coun", res);
      setCountry(res?.data);
    } else {
      setGetting(false);
      setCountry(null);
      console.log("team err", res);
    }
  };

  // const createLeague = async (e) => {
  //   e.preventDefault();
  //   axios.defaults.headers.common = {
  //     Authorization: `Bearer ${getToken()}`,
  //   };
  //   if (type == "League") {
  //     if (number > 3 && leagueName != "") {
  //       setLoading(true);
  //       let carrier = {
  //         name: leagueName,
  //         start_date: startDate,
  //         end_date: endDate,
  //         type_id: type === "League" ? "1" : "2",
  //         is_home_away: "0",
  //         banner: "ddddd",
  //         team_id: team?.id,
  //         number_of_teams: parseInt(number),
  //         is_owner_participate: true,
  //         is_private: lock,
  //       };
  //       const res = await api.create("tornament", carrier);
  //       if (res.status) {
  //         setLoading(false);
  //         toast.success(
  //           type === "League"
  //             ? "League created Successfully"
  //             : "Tournament created Successfully"
  //         );
  //         getCurrent(count);
  //       } else {
  //         setLoading(false);
  //         console.log("league err", res);
  //       }
  //     } else {
  //       toast.info("Minimum number of teams is 4");
  //     }
  //   } else {
  //     if ((number == 4 || number == 8 || number == 16) && leagueName != "") {
  //       setLoading(true);
  //       let carrier = {
  //         name: leagueName,
  //         start_date: startDate,
  //         end_date: endDate,
  //         type_id: type === "League" ? "1" : "2",
  //         is_home_away: "0",
  //         banner: "ddddd",
  //         number_of_teams: parseInt(number),
  //         is_owner_participate: true,
  //         is_private: lock,
  //       };
  //       const res = await api.create("tornament", carrier);
  //       if (res.status) {
  //         setLoading(false);
  //         toast.success(
  //           type === "League"
  //             ? "League created Successfully"
  //             : "Tournament created Successfully"
  //         );
  //         getCurrent(count);
  //       } else {
  //         setLoading(false);
  //         console.log("league err", res);
  //       }
  //     } else {
  //       toast.info("Team number can either be 4, 8 or 16");
  //     }
  //   }
  // };

  const payload = {
    name: leagueName,
    number: number,
    private: lock,
  };

  return (
    <Wrapper>
      {getting ? (
        <div className="holding">
          <ReactLoading
            type={"balls"}
            color={"#f80033"}
            height={"20%"}
            width={"20%"}
          />
        </div>
      ) : (
        <>
          <Flex>
            <h4>{type} Name</h4>
            <p className="mini">
              {type == "League" ? "Maximum" : "Max"} 30 characters
            </p>
          </Flex>
          <DarkInput
            type="text"
            value={leagueName}
            onChange={(e) => setLeagueName(e.target.value)}
          />
          <p>The title of your league</p>
          <Spacer height="0.2rem" />
          <h4>Number of players</h4>
          {type == "League" ? (
            <NumInput
              type="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          ) : (
            <div className="selector">
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={players[0]}
                isDisabled={isDisabled}
                isLoading={isLoading}
                onChange={setNumber}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                name="time"
                options={players}
              />
            </div>
          )}
          <p>
            Minimum of four <span style={{ color: "white" }}>(4)</span> teams to
            <br />
            start your {type.toLowerCase()}
          </p>
          <Spacer height="0.2rem" />
          <h4>Private {type}</h4>
          <DarkFlex>
            <FaLock
              onClick={() => {
                setLock(true);
              }}
              className={lock ? "active" : "inactive"}
            />
            <FaUnlock
              onClick={() => {
                setLock(false);
              }}
              className={!lock ? "active" : "inactive"}
            />
          </DarkFlex>
          <p>
            Locked; your league is closed to other users
            <br />
            Unlocked; your league is open to the public
          </p>
          <Spacer height="0.5rem" />
          <BtnDiv>
            <PinkButton
              onClick={() => {
                getRules(true);
                getCountry(country);
                getData(payload);
              }}
              disabled={number == "" && leagueName == "" ? true : false}
            >
              {getting ? "..." : `${type} Rules`}{" "}
            </PinkButton>
            {/* <WhiteButton
              size="half"
              onClick={(e) => createLeague(e)}
              isloading={loading}
            >
              Complete
            </WhiteButton> */}
          </BtnDiv>
        </>
      )}
    </Wrapper>
  );
}

export default CreateLeague;
