export const AppColors = {
  BLACK: "#000000",
  WHITE: "#FFFFFF",
  YELLOW: "#F8A42F",
  LIGHTGREY: "#C2C2C2",
  LightBlue: "#F0F4FF",
  DarkGrey: "#737373",
  GreyShade: "#F8F8F8",
  TwitterBlue: "#4ec4fd",
  FBblue: "#4E7FFD",
  SLGrey: "#ECECEC",
  LightBlack: "#2A2A2A",
  ThinGrey: "#616161",
  Success: "#30D887",
  OFF_WHITE: "#FAFAFA",
  LightYellow: "#FDF1E0",
  PEACH: "#FFF0EB",
  Trans: "#3D3D3D",
  DashBG: "#2B2B2B",
  DarkPeach: "#FF7C53",
  LightGreen: "#E9FCF3",
  red: "#D83030",
  Pinkish: "#f80033",
  TransYELLOW: "#fdf1e078",
  SkyBlue: "#4e7ffd36",
  DropDownBorder: "#535353",
  tabsGrey: "#282828",
  lightPink: "#9a1038",
  PURPLE: "#1d161e",
};
