import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BioOptions from "../../../components/bioOptions/BioOptions";
import Selectors from "../../../components/selectors/Selectors";
import SettingOptions from "../../../components/settingOptions/SettingOptions";
import TabSwitch from "../../../components/tabs/TabSwitch";
import { Flexed } from "../../Login";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import Spacer from "../../../components/spacer/Spacer";
import Preferences from "../../../components/preferences/Preferences";
import Security from "../../../components/security/Security";
import ViewRecord from "../../../components/record/ViewRecord";
import EditBio from "../../../components/editbio/EditBio";
import ChangePassword from "../../ChangePassword";
import Delete from "../../../components/deleteaccount/Delete";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toast";
import { useSelector } from "react-redux";
import { selectUser } from "../../../reduxfeatures/user/userSlice";
import Logo from "../../../components/Logo/Logo";

const Wrapper = styled.div`
  position: relative;
  padding: 0rem;
  width: -webkit-fill-available;

  @media (min-width: 768px) {
    display: grid;
    place-items: center;
    padding: 2rem 2rem 0 2rem;
  }
  h2 {
    color: white;
  }

  .footing {
    max-width: 1000px;
    overflow-x: hidden;
    min-width: 380px;

    @media (max-width: 768px) {
      padding: 1vh 0;
    }
  }

  .b-holding {
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 4%;
    left: 50%;
    transform: translate(-50%, 50%);
    background: #1c2127;
    width: 100%;

    @media (max-width: 768px) {
      bottom: 5%;
    }
  }
`;

export const Curved = styled.div`
  position: relative;
  display: flex;
  background: hsl(224deg 29.41% 10% / 55%);
  justify-content: center;
  padding: 1rem 1.5rem 2rem 1.5rem;
  width: 550px;

  @media (max-width: 768px) {
    margin: 0 0.5rem;
    width: unset;
  }

  .hold {
    margin-top: 1rem;
    width: 100%;
    z-index: 50;
    @media (min-width: 768px) {
      margin-top: 0rem;
    }
  }
`;

export const AppWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
`;

function Profile() {
  const navigate = useNavigate();
  const [hit, setHit] = useState(false);
  const [option, setOption] = useState("");
  const [rules, setRules] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const token = localStorage.getItem("PRivalsToken");
  let user = useSelector(selectUser);

  useEffect(() => {
    if (!token) {
      toast.error("Access Denied, Kindly Login to get access");
      navigate("/");
    }
  }, []);

  const getHit = (data) => {
    setHit(data);
  };

  const getOption = (data) => {
    setOption(data);
  };
  const getCurrent = (data) => {
    setCurrentTab(data);
  };
  const getRules = (data) => {
    setRules(data);
  };

  return (
    <Wrapper>
      <div className="inner-wrapper">
        <Logo />
        <Spacer height="0.2rem" />
        <Curved>
          <div className="hold">
            <Spacer height="1rem" />
            <AppWrapper>
              {!hit && (
                <>
                  <TabSwitch
                    current={currentTab}
                    fTitle="Bio"
                    sTitle="Account settings"
                    fContent={
                      <BioOptions getHit={getHit} getOption={getOption} />
                    }
                    sContent={
                      <SettingOptions getHit={getHit} getOption={getOption} />
                    }
                  />
                </>
              )}
              {hit && (
                <>
                  {option === "pref" && (
                    <Preferences getOption={getOption} getHit={getHit} />
                  )}
                  {option === "security" && (
                    <Security getHit={getHit} getOption={getOption} />
                  )}
                  {option === "change" && (
                    <ChangePassword getOption={getOption} />
                  )}
                  {option === "record" && (
                    <ViewRecord
                      getHit={getHit}
                      getOption={getOption}
                      getCurrent={getCurrent}
                    />
                  )}
                  {option === "bio" && (
                    <EditBio
                      user={user}
                      getHit={getHit}
                      getOption={getOption}
                      getCurrent={getCurrent}
                    />
                  )}
                  {option === "delete" && (
                    <Delete getHit={getHit} getOption={getOption} />
                  )}
                </>
              )}
            </AppWrapper>
            {!hit && <Spacer height="15vh" />}
            {(option === "pref" ||
              option === "record" ||
              option === "bio" ||
              option === "change") &&
              hit && <Spacer height="3vh" />}
            {(option === "security" || option === "delete") && hit && (
              <Spacer height="33vh" />
            )}
          </div>
        </Curved>
        <Spacer height="10rem" />
      </div>
      <div className="b-holding">
        <div className="footing">
          <Selectors />
          {/* <Flexed>
            <FaFacebookF className="grey" />
            <FaInstagram className="grey" />
            <BsTwitter className="grey" />
            <FaYoutube className="grey" />
          </Flexed> */}
        </div>
      </div>
    </Wrapper>
  );
}

export default Profile;
