import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BsChevronRight } from "react-icons/bs";
import { AppColors } from "../../config/AppColors";

const Wrapper = styled.div`
  position: relative;
`;
const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(193 193 193 / 15%);

  cursor: pointer;
  padding: 0 1rem;

  &:hover {
    background: rgb(160 159 159 / 5%);
  }

  p {
    color: ${AppColors.WHITE};
  }
  svg {
    fill: ${AppColors.WHITE};
  }
`;

function BioOptions({ getHit, getOption }) {
  const [option, setOption] = useState("");

  useEffect(() => {
    getOption(option);
    if (option !== "") {
      getHit(true);
    }
  }, [option]);
  return (
    <Wrapper>
      <Flex
        onClick={() => {
          setOption("record");
        }}
      >
        <p>View record</p>
        <BsChevronRight />
      </Flex>
      <Flex
        onClick={() => {
          setOption("bio");
        }}
      >
        <p>Edit bio</p>
        <BsChevronRight />
      </Flex>
    </Wrapper>
  );
}

export default BioOptions;
