import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import Leagues from "./pages/overview/Leagues/Leagues";
import Profile from "./pages/overview/Profile/Profile";
import Tournaments from "./pages/overview/Tournaments/Tournaments";
import SignUp from "./pages/SignUp";
import "./scss/style.css";
import { ToastContainer } from "react-toast";
import Home from "./pages/overview/Home/Home";
import Welcome from "./pages/Welcome";
import HowToUse from "./pages/HowToUse";
import VerifyOTP from "./pages/VerifyOTP";
import ResetPassword from "./pages/ResetPassword";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/register" element={<SignUp />} />
          <Route path="/" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/leagues" element={<Leagues />} />
          <Route path="/tournaments" element={<Tournaments />} />
          <Route path="/home" element={<Home />} />
          <Route path="/home/welcome" element={<Welcome />} />
          <Route path="/home/how-to-use" element={<HowToUse />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-otp" element={<VerifyOTP />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer delay={3000} position="top-right" />
    </div>
  );
}

export default App;
