import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AppColors } from "../../config/AppColors";
import { WrapInput } from "../forms/Form";
import DarkInput from "../Inputs/DarkInput";
import { BiSolidPencil } from "react-icons/bi";
import Input from "../Inputs/Input";
import Spacer from "../spacer/Spacer";
import FullButton from "../buttons/FullButton";
import axios from "axios";
import { getToken } from "../../utils";
import api from "../../api/api";
import { useDispatch } from "react-redux";
import { teamSaved } from "../../reduxfeatures/team/teamSlice";
import { toast } from "react-toast";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: hsl(224deg 29.41% 10% / 55%);
  h4 {
    color: ${AppColors.WHITE};
    font-size: 1rem;
    padding: 0 2.5rem;
  }

  img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 1rem;

  svg {
    position: absolute;
    padding: 1rem;
    top: 0.4rem;
    left: 44%;
    fill: ${AppColors.WHITE};
    stroke: ${AppColors.WHITE};
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  p {
    color: ${AppColors.WHITE};
    font-size: 0.8rem;
  }
`;

const Header = styled.p`
  position: relative;
  background: rgb(193 193 193 / 15%);
  color: ${AppColors.WHITE};
  font-size: 1.2rem;
  margin: 0;
  padding: 1rem 0;
  text-align: center;

  .back {
    position: absolute;
    left: 5%;
    cursor: pointer;
  }
`;

const InnerWrap = styled.div`
  position: relative;
  padding: 1rem 10%;
`;

const NumInput = styled.input`
  display: block;
  border-radius: 2rem;
  padding: 1.3em 1em 1.3em 4em;
  width: 100%;
  box-sizing: border-box;
  background-color: ${AppColors.Trans};
  color: ${AppColors.OFF_WHITE};

  border: none;
  font-weight: 700;

  &:focus {
    outline: none;
  }

  @media (max-width: 360px) {
    padding: 1.3em 1em 1.3em 3em;
  }
`;

function EditBio({ user, getOption, getHit, getCurrent }) {
  const inputElem = useRef();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState(user?.username);
  const [teamName, setTeamName] = useState("...");
  const [pNum, setPNum] = useState(user?.phone);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [data, setData] = useState(null);
  const handleOnWheel = (e) => {
    // The blur event fires when an element has lost focus. The event does not bubble,
    inputElem.current.blur();
  };

  // useEffect(() => {
  //   getTeam();
  // }, []);

  // const getTeam = async (e) => {
  //   axios.defaults.headers.common = {
  //     Authorization: `Bearer ${getToken()}`,
  //   };
  //   setLoading(true);
  //   const res = await api.getAll("team");
  //   if (res) {
  //     setLoading(false);
  //     console.log(res);
  //     setTeamName(res?.data.length > 0 ? res?.data?.[0].name : "");
  //     dispatch(teamSaved(res?.data[0]));
  //   } else {
  //     setLoading(false);
  //     setTeamName("");
  //     console.log("team err", res);
  //   }
  // };

  // const submit = async (e) => {
  //   e.preventDefault();
  //   if (teamName) {
  //     let payload = {
  //       name: teamName,
  //     };
  //     axios.defaults.headers.common = {
  //       Authorization: `Bearer ${getToken()}`,
  //     };
  //     setUpdating(true);
  //     const res = await api.create("update", payload);
  //     if (res.status) {
  //       console.log("data", res);
  //       setUpdating(false);
  //       setData(res?.data);
  //       toast.success("Update successful");
  //     } else {
  //       setUpdating(false);
  //       setData(null);
  //       console.log("submit err", res);
  //     }
  //   }
  // };

  return (
    <Wrapper>
      <Header>
        {" "}
        <HiOutlineArrowNarrowLeft
          className="back"
          onClick={() => {
            getCurrent(1);
            getHit(false);
            getOption("");
          }}
        />{" "}
        Your Bio
      </Header>
      <InnerWrap>
        <Flex>
          <img src="/images/mini.jpg" />
          <BiSolidPencil />
        </Flex>
        <Spacer height="1rem" />
        <WrapInput>
          <DarkInput
            placeholder="Username"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            disabled={true}
          />
        </WrapInput>
        {/* <WrapInput>
          <DarkInput
            placeholder="Team name"
            value={teamName}
            type="text"
            onChange={(e) => setTeamName(e.target.value)}
          />
        </WrapInput> */}
        <WrapInput>
          <DarkInput placeholder="Email" disabled={true} value={user?.email} />
        </WrapInput>
        {/* <WrapInput>
          <NumInput
            placeholder="Phone number"
            value={pNum}
            type="number"
            ref={inputElem}
            onWheel={handleOnWheel}
            name="Phone Number"
            onChange={(e) => setPNum(e.target.value)}
            disabled={true}
          />
        </WrapInput> */}
        {/* <Spacer height="1.5rem" />
        <FullButton
          isloading={updating}
          bg="#f80033"
          color="white"
          onClick={submit}
        >
          Confirm
        </FullButton> */}
      </InnerWrap>
    </Wrapper>
  );
}

export default EditBio;
