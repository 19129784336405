import React, { useState } from "react";
import { BsTwitter } from "react-icons/bs";
import {
  FaFacebookF,
  FaInstagram,
  FaRegEnvelope,
  FaYoutube,
} from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import api from "../api/api";
import Form from "../components/forms/Form";
import Logo from "../components/Logo/Logo";
import Spacer from "../components/spacer/Spacer";
import { AppColors } from "../config/AppColors";
import { toast } from "react-toast";
import { useDispatch } from "react-redux";
import { userSaved } from "../reduxfeatures/user/userSlice";

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 5vh 10vw;
  overflow: hidden !important;

  @media (max-width: 768px) {
    padding: 0rem;
    width: -webkit-fill-available;
    overflow: hidden !important;
  }

  h1 {
    color: ${AppColors.WHITE};
    padding: 1rem 0;
  }

  .sign-route {
    color: ${AppColors.WHITE};

    span {
      color: ${AppColors.red};
      cursor: pointer;
    }
  }
  .connect {
    color: ${AppColors.DarkGrey};
  }
`;

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Flexed = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;

  .svg-wrap-e {
    padding: 0.5rem;
    background-color: ${AppColors.red};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .svg-wrap-f {
    padding: 0.5rem;
    background-color: ${AppColors.FBblue};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .svg-wrap-t {
    padding: 0.5rem;
    background-color: ${AppColors.TwitterBlue};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .svg-wrap-g {
    padding: 0.5rem;
    background-color: ${AppColors.WHITE};
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .e,
  .f,
  .t {
    fill: ${AppColors.WHITE};
  }

  .grey {
    fill: ${AppColors.LIGHTGREY};
    margin: 0 0.8rem;
    font-size: 1.2rem;
  }
`;

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [payload, setPayload] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);

  const getPayload = (data) => {
    setPayload(data);
  };

  const loginRequest = async (e) => {
    e.preventDefault();
    if (payload.loginVal.length > 2 && payload.password.length > 3) {
      setLoading(true);
      let courier = {
        email: payload.loginVal,
        password: payload.password,
      };
      const res = await api.create("login", courier);
      if (res?.data?.token) {
        setLoading(false);
        setValue(res);
        dispatch(userSaved(res.data));
        localStorage.setItem("PRivalsToken", res?.data.token);
        toast.success("Login Successful");
        navigate("/home");
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <Wrapper>
      <div className="inner-wrapper">
        <Logo />
        <FormWrapper>
          <Form
            type="login"
            getPayload={getPayload}
            loading={loading}
            request={(e) => loginRequest(e)}
          />
        </FormWrapper>
        <Spacer height="1rem" />
        <p className="connect">Or connect using</p>
        <Spacer height="0.5rem" />
        <Flexed>
          <div className="svg-wrap-e">
            <FaRegEnvelope className="e" />
          </div>
          <div className="svg-wrap-f">
            <FaFacebookF className="f" />
          </div>
          <div className="svg-wrap-t">
            <BsTwitter className="t" />
          </div>
          <div className="svg-wrap-g">
            <FcGoogle className="g" />
          </div>
        </Flexed>
        <Spacer height="1rem" />
        <p className="sign-route">
          New User? <span onClick={() => navigate("/register")}>Sign Up</span>
        </p>
        <Spacer height="1rem" />
        <Flexed>
          <FaFacebookF className="grey" />
          <FaInstagram className="grey" />
          <BsTwitter className="grey" />
          <FaYoutube className="grey" />
        </Flexed>
        <Spacer height="1rem" />
      </div>
    </Wrapper>
  );
}

export default Login;
