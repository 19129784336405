import React, { useState } from "react";
import styled from "styled-components";
import { BsChevronRight } from "react-icons/bs";
import { AppColors } from "../../config/AppColors";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: hsl(224deg 29.41% 10% / 55%);
  h4 {
    color: ${AppColors.WHITE};
    font-size: 1rem;
    padding: 0 2.5rem;
  }
`;
const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 2.5rem;

  &:hover {
    background: rgb(160 159 159 / 5%);
  }

  p {
    color: ${AppColors.WHITE};
  }
  svg {
    fill: ${AppColors.WHITE};
  }

  input[type="radio"] {
    display: grid;
    place-content: center;
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    color: red;
    width: 1.5em;
    height: 1.5em;
    border: 0.15em solid ${AppColors.DarkGrey};
    cursor: pointer;
    border-radius: 50%;
    transform: translateY(-0.075em);
  }

  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${AppColors.WHITE};
    cursor: pointer;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }
  input[type="radio"]:checked {
    background-color: ${AppColors.Pinkish};
    border: none;
  }
`;

const Header = styled.p`
  position: relative;
  background: rgb(193 193 193 / 15%);
  color: ${AppColors.WHITE};
  font-size: 1.2rem;
  margin: 0;
  padding: 1rem 0;
  text-align: center;

  .back {
    position: absolute;
    left: 5%;
    cursor: pointer;
  }
`;

function Preferences({ getOption, getHit }) {
  const [choice, setChoice] = useState("eoff");
  const [mChoice, setMChoice] = useState("moff");
  return (
    <Wrapper>
      <Header>
        {" "}
        <HiOutlineArrowNarrowLeft
          className="back"
          onClick={() => {
            getHit(false);
            getOption("");
          }}
        />{" "}
        PREFERENCES
      </Header>
      <h4>Email notifications</h4>
      <Flex>
        <p>Off</p>
        <input
          type="radio"
          id="eoff"
          name="eradio"
          value="eoff"
          checked={choice === "eoff"}
          onChange={() => {
            setChoice("eoff");
          }}
        />
      </Flex>
      <Flex>
        <div>
          <p>On</p>
        </div>
        <input
          type="radio"
          id="eon"
          name="eradio"
          value="eon"
          checked={choice === "eon"}
          onChange={() => {
            setChoice("eon");
          }}
        />
      </Flex>
      <h4>Marketing emails</h4>
      <Flex>
        <p>Off</p>
        <input
          type="radio"
          id="moff"
          name="mradio"
          value="moff"
          checked={mChoice === "moff"}
          onChange={() => {
            setMChoice("moff");
          }}
        />
      </Flex>
      <Flex>
        <p>On</p>
        <input
          type="radio"
          id="mon"
          name="mradio"
          value="mon"
          checked={mChoice === "mon"}
          onChange={() => {
            setMChoice("mon");
          }}
        />
      </Flex>
    </Wrapper>
  );
}

export default Preferences;
