import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { getToken } from "../../../../utils";
import api from "../../../../api/api";
import { LoadInterface } from "../../Leagues/paths/Fixtures";
import { ImTrophy } from "react-icons/im";
import { AppColors } from "../../../../config/AppColors";
import MatchUps from "../../Leagues/components/MatchUps";
import Spacer from "../../../../components/spacer/Spacer";
import { GiCheckMark } from "react-icons/gi";
import { toast } from "react-toast";

const Wrapper = styled.div`
  position: relative;

  .none {
    color: ${AppColors.WHITE};
    padding: 0 1.5rem;
    text-align: center;
  }
`;

const MatchWraps = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  padding: 0;

  .scores {
    padding: 0.25rem 0rem;
    margin: 0 0.5rem;
    border-radius: 0.4rem;
    background-color: ${AppColors.Pinkish};
    color: ${AppColors.WHITE};
    width: 1.5rem;
    height: 0.8rem;
    border: none;
    outline: none;
    text-align: center;
  }
  span {
    color: white;
  }

  .mark {
    margin-left: 0.25rem;
    fill: ${AppColors.Success};
    cursor: pointer;
  }
`;

function SubmitResults({ details, type }) {
  const [fetching, setFetching] = useState(false);
  const [info, setInfo] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [home, setHome] = useState(0);
  const [away, setAway] = useState(0);
  const [firstVal, setFirstVal] = useState(false);
  const [secondVal, setSecondVal] = useState(false);
  let id;

  useEffect(() => {
    if (details) {
      getGameById();
    }
  }, [details, data]);

  const getGameById = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setFetching(true);
    const res = await api.getAll("match");
    if (res) {
      setFetching(false);
      const filtered = res?.data.filter(
        (x) => x.is_completed === 0 && x.event.game_type_id === 2
      );
      setInfo(filtered);
    } else {
      setFetching(false);
      setInfo(null);
      console.log("tour err", res);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    if (id && firstVal && secondVal) {
      if (home != away) {
        let payload = {
          away_team_goals: away,
          home_team_goals: home,
          is_completed: "1",
          event_id: details?.id,
        };
        axios.defaults.headers.common = {
          Authorization: `Bearer ${getToken()}`,
        };
        setLoading(true);
        const res = await api.patchUp(`match/${id}`, payload);
        if (res.status) {
          console.log("data", res);
          setLoading(false);
          setData(res?.data);
          toast.success("Result uploaded");
        } else {
          setLoading(false);
          setData(null);
          console.log("submit err", res);
        }
      } else {
        toast.info(
          "For single match tournaments each fixture must have a winner"
        );
      }
    }
  };

  return (
    <Wrapper>
      {fetching || loading ? (
        <LoadInterface>
          <ImTrophy className="trophy" />
        </LoadInterface>
      ) : info?.length > 0 ? (
        <>
          {info?.map((x, index) => (
            <>
              <MatchWraps key={x?.id}>
                <MatchUps text={x?.home_team} type="match" position="home" />
                <input
                  className="scores"
                  onChange={(e) => {
                    setHome(e.target.value);
                    setFirstVal(true);
                  }}
                />
                <span> - </span>
                <input
                  className="scores"
                  onChange={(e) => {
                    setAway(e.target.value);
                    setSecondVal(true);
                  }}
                />
                <MatchUps text={x?.away_team} type="match" position="away" />
                <GiCheckMark
                  className="mark"
                  onClick={(e) => {
                    id = x.id;
                    submit(e);
                  }}
                />
              </MatchWraps>
            </>
          ))}
        </>
      ) : (
        <div className="none">
          <Spacer height="2rem" />
          <p>{type} has not started</p>
        </div>
      )}
    </Wrapper>
  );
}

export default SubmitResults;
