import React, { useEffect, useState } from "react";
import { Tree } from "tournament-bracket-tree";
import { TreeGenerator } from "tournament-bracket-tree";
import "tournament-bracket-tree/dist/index.css";
import styled from "styled-components";
import { ImTrophy } from "react-icons/im";
import { AppColors } from "../../../../config/AppColors";
import axios from "axios";
import { getToken } from "../../../../utils";
import api from "../../../../api/api";
import Spacer from "../../../../components/spacer/Spacer";
import { LoadInterface } from "../../Leagues/paths/Fixtures";

const Wrapper = styled.div`
  position: relative;
  margin-top: 2rem;

  @media (min-width: 768px) {
    display: grid;
    place-items: center;
  }

  ._1kbaO,
  #exit-spacer {
    min-width: 5px !important;
  }

  ._-WYis {
    min-width: 0px !important;
  }

  .none {
    color: ${AppColors.WHITE};
    padding: 0 1.5rem;
    text-align: center;
  }
`;

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;

  .trophy {
    fill: ${AppColors.WHITE};
    font-size: 5rem;
  }

  .winner {
    text-align: center;

    p {
      color: ${AppColors.WHITE};
    }
  }
`;

export default function Bracket({ details }) {
  const [fetching, setFetching] = useState(false);
  const [info, setInfo] = useState([]);
  const [datum, setDatum] = useState(null);
  const [getting, setGetting] = useState(false);
  const [winner, setWinner] = useState([]);

  useEffect(() => {
    if (details) {
      getGameById();
      getWinner();
    }
  }, [details]);

  const getGameById = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setFetching(true);
    const res = await api.getById("tornament", details?.id, "info");
    if (res) {
      setFetching(false);
      setDatum(res?.data);
      setInfo(res?.data?.table);
    } else {
      setFetching(false);
      setInfo(null);
      console.log("tour err", res);
    }
  };

  const getWinner = async (e) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${getToken()}`,
    };
    setGetting(true);
    const res = await api.getById("tornament", details?.id, "winner");
    if (res) {
      setGetting(false);
      console.log(res);
      setWinner(res?.data.winner);
    } else {
      setGetting(false);
      setWinner(null);
      console.log("tour err", res);
    }
  };

  console.log("check", datum);
  // 1. Construct your tree
  // Define your type for one single node

  const myTree = {
    data: {
      teamOne: info?.["Round 2"] ? info?.["Round 2"]?.[0].home_team : "?",
      scoreTeamOne: info?.["Round 2"]
        ? info?.["Round 2"]?.[0].home_team_goals
        : 0,
      teamTwo: info?.["Round 2"] ? info?.["Round 2"]?.[0].away_team : "?",
      scoreTeamTwo: info?.["Round 2"]
        ? info?.["Round 2"]?.[0].away_team_goals
        : 0,
    },
    left: {
      data: {
        teamOne: info?.["Round 1"]?.[0].home_team,
        scoreTeamOne: info?.["Round 1"]?.[0].home_team_goals,
        teamTwo: info?.["Round 1"]?.[0].away_team,
        scoreTeamTwo: info?.["Round 1"]?.[0].away_team_goals,
      },
    },
    right: {
      data: {
        teamOne: info?.["Round 1"]?.[1].home_team,
        scoreTeamOne: info?.["Round 1"]?.[1].home_team_goals,
        teamTwo: info?.["Round 1"]?.[1].away_team,
        scoreTeamTwo: info?.["Round 1"]?.[1].away_team_goals,
      },
    },
  };

  const eightFunction = (info) => {
    const myTree = {
      data: {
        teamOne: info?.["Round 3"] ? info?.["Round 3"]?.[0].home_team : "?",
        scoreTeamOne: info?.["Round 3"]
          ? info?.["Round 3"]?.[0].home_team_goals
          : 0,
        teamTwo: info?.["Round 3"] ? info?.["Round 3"]?.[0].away_team : "?",
        scoreTeamTwo: info?.["Round 3"]
          ? info?.["Round 3"]?.[0].away_team_goals
          : 0,
      },
      left: {
        data: {
          teamOne: info?.["Round 2"] ? info?.["Round 2"]?.[0].home_team : "?",
          scoreTeamOne: info?.["Round 2"]
            ? info?.["Round 2"]?.[0].home_team_goals
            : 0,
          teamTwo: info?.["Round 2"] ? info?.["Round 2"]?.[0].away_team : "?",
          scoreTeamTwo: info?.["Round 2"]
            ? info?.["Round 2"]?.[0].away_team_goals
            : 0,
        },
        left: {
          data: {
            teamOne: info?.["Round 1"] ? info?.["Round 1"]?.[0].home_team : "?",
            scoreTeamOne: info?.["Round 1"]
              ? info?.["Round 1"]?.[0].home_team_goals
              : 0,
            teamTwo: info?.["Round 1"] ? info?.["Round 1"]?.[0].away_team : "?",
            scoreTeamTwo: info?.["Round 1"]
              ? info?.["Round 1"]?.[0].away_team_goals
              : 0,
          },
        },
        right: {
          data: {
            teamOne: info?.["Round 1"] ? info?.["Round 1"]?.[1].home_team : "?",
            scoreTeamOne: info?.["Round 1"]
              ? info?.["Round 1"]?.[1].home_team_goals
              : 0,
            teamTwo: info?.["Round 1"] ? info?.["Round 1"]?.[1].away_team : 0,
            scoreTeamTwo: info?.["Round 1"]
              ? info?.["Round 1"]?.[1].away_team_goals
              : 0,
          },
        },
      },
      right: {
        data: {
          teamOne: info?.["Round 2"] ? info?.["Round 2"]?.[1].home_team : "?",
          scoreTeamOne: info?.["Round 2"]
            ? info?.["Round 2"]?.[1].home_team_goals
            : 0,
          teamTwo: info?.["Round 2"] ? info?.["Round 2"]?.[1].away_team : "?",
          scoreTeamTwo: info?.["Round 2"]
            ? info?.["Round 2"]?.[1].away_team_goals
            : 0,
        },
        left: {
          data: {
            teamOne: info?.["Round 1"] ? info?.["Round 1"]?.[2].home_team : "?",
            scoreTeamOne: info?.["Round 1"]
              ? info?.["Round 1"]?.[2].home_team_goals
              : 0,
            teamTwo: info?.["Round 1"] ? info?.["Round 1"]?.[2].away_team : "?",
            scoreTeamTwo: info?.["Round 1"]
              ? info?.["Round 1"]?.[2].away_team_goals
              : "?",
          },
        },
        right: {
          data: {
            teamOne: info?.["Round 1"] ? info?.["Round 1"]?.[3].home_team : "?",
            scoreTeamOne: info?.["Round 1"]
              ? info?.["Round 1"]?.[3].home_team_goals
              : 0,
            teamTwo: info?.["Round 1"] ? info?.["Round 1"]?.[3].away_team : "?",
            scoreTeamTwo: info?.["Round 1"]
              ? info?.["Round 1"]?.[3].away_team_goals
              : 0,
          },
        },
      },
    };

    return myTree;
  };

  // 2. Create map function
  const mapTournamentToNode = (game) => {
    return (
      // Tip: using flexbox helps to make it responsive
      <div
        style={{
          height: "55px",
          width: "6rem",
          display: "flex",
          background: "rgb(193 193 193 / 15%)",
          flexDirection: "column",
          color: "white",
          // Don't forget to add margins to the outer container
          margin: "10px 0",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p
            style={{
              margin: 0,
              paddingLeft: "2px",
              fontSize: "0.8rem",
            }}
          >
            {game.teamOne}
          </p>
          <p
            style={{
              margin: 0,
              paddingRight: "2px",
              backgroundColor: `${
                game.scoreTeamOne > game.scoreTeamTwo ? "#f80033" : "#737373"
              }`,
              padding: "0.2rem 0.5rem",
              fontSize: "0.8rem",
            }}
          >
            {game.scoreTeamOne}
          </p>
        </div>
        <div
          style={{
            flex: 1,
            borderTop: "10px solid transparent",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{ margin: 0, paddingLeft: "2px", fontSize: "0.8rem" }}>
            {game.teamTwo}
          </p>
          <p
            style={{
              margin: 0,
              backgroundColor: `${
                game.scoreTeamOne > game.scoreTeamTwo ? "#737373" : "#f80033"
              }`,
              padding: "0.2rem 0.5rem",
              fontSize: "0.8rem",
            }}
          >
            {game.scoreTeamTwo}
          </p>
        </div>
      </div>
    );
  };
  console.log("dec", info);

  console.log("dat", datum);
  return (
    <Wrapper>
      {/* 3. Combine map function and tree data into bracket: */}
      {fetching ? (
        <LoadInterface>
          <ImTrophy className="trophy" />
        </LoadInterface>
      ) : info?.length === 0 ? (
        <div className="none">
          <Spacer height="2rem" />
          <p>No result yet</p>
        </div>
      ) : (
        <Inner>
          {" "}
          <TreeGenerator
            root={"right"}
            mapDataToNode={mapTournamentToNode}
            tree={info?.["Round 1"].length == 2 ? myTree : eightFunction(info)}
            lineThickness={1} // Optional
            lineColor="red" // Optional
            lineStyle="solid" // Optional
            lineLength={50} // Optional
          />
          <div className="winner">
            <ImTrophy className="trophy" />
            {datum.winner !== null && (
              <>
                {info?.["Round 3"] &&
                  info?.["Round 3"]?.[0].is_completed === 1 && (
                    <p>
                      {parseInt(info?.["Round 3"][0].home_team_goals) >
                      parseInt(info?.["Round 3"][0].away_team_goals)
                        ? info?.["Round 3"][0].home_team
                        : info?.["Round 3"][0].away_team}
                    </p>
                  )}
                {!info?.["Round 3"] &&
                  info?.["Round 2"] &&
                  info?.["Round 2"]?.[0].is_completed === 1 && (
                    <p>
                      {parseInt(info?.["Round 2"][0].home_team_goals) >
                      parseInt(info?.["Round 2"][0].away_team_goals)
                        ? info?.["Round 2"][0].home_team
                        : info?.["Round 2"][0].away_team}
                    </p>
                  )}
              </>
            )}
          </div>
        </Inner>
      )}
    </Wrapper>
  );
}
