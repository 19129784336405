import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Holder from "../holder/Holder";
import { FaTrophy } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { GiBoltShield } from "react-icons/gi";
import { AppColors } from "../../config/AppColors";
import ActiveHolder from "../holder/ActiveHolder";
import Spacer from "../spacer/Spacer";
import { useLocation, useNavigate } from "react-router-dom";

const Container = styled.div`
  position: relative;
`;

const Flexed = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 3%;

  svg {
    font-size: 2rem;
    fill: ${AppColors.WHITE};
  }

  p {
    color: ${AppColors.WHITE};
    font-size: 0.8rem;
    margin: 0;
    @media (max-width: 760px) {
      font-size: 0.65rem;
    }
  }
`;

function Selectors() {
  const [val, setVal] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setVal(location.pathname.slice(1));
  }, []);

  return (
    <Container>
      <Flexed>
        {val.includes("home") ? (
          <ActiveHolder onClick={() => setVal("home")}>
            <AiFillHome />
            <Spacer height="0.5rem" />
            <p>Home</p>
          </ActiveHolder>
        ) : (
          <Holder
            onClick={() => {
              setVal("home");
              navigate("/home");
            }}
          >
            <AiFillHome />
            <Spacer height="0.5rem" />
            <p>Home</p>
          </Holder>
        )}
        {val !== "leagues" ? (
          <Holder
            onClick={() => {
              setVal("leagues");
              navigate("/leagues");
            }}
          >
            <FaTrophy />
            <Spacer height="0.5rem" />
            <p>Leagues</p>
          </Holder>
        ) : (
          <ActiveHolder onClick={() => setVal("leagues")}>
            <FaTrophy />
            <Spacer height="0.5rem" />
            <p>Leagues</p>
          </ActiveHolder>
        )}
        {val === "tournaments" ? (
          <ActiveHolder onClick={() => setVal("tournaments")}>
            <GiBoltShield />
            <Spacer height="0.5rem" />
            <p>Tournaments</p>
          </ActiveHolder>
        ) : (
          <Holder
            onClick={() => {
              setVal("tournaments");
              navigate("/tournaments");
            }}
          >
            <GiBoltShield />
            <Spacer height="0.5rem" />
            <p>Tournaments</p>
          </Holder>
        )}
        {val === "profile" ? (
          <ActiveHolder onClick={() => setVal("profile")}>
            <FaUser />
            <Spacer height="0.5rem" />
            <p>Profile</p>
          </ActiveHolder>
        ) : (
          <Holder
            onClick={() => {
              setVal("profile");
              navigate("/profile");
            }}
          >
            <FaUser />
            <Spacer height="0.5rem" />
            <p>Profile</p>
          </Holder>
        )}
      </Flexed>
    </Container>
  );
}

export default Selectors;
