import React, { useEffect, useState } from "react";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import styled from "styled-components";
import Selectors from "../../../components/selectors/Selectors";
import Spacer from "../../../components/spacer/Spacer";
import TourTab from "../../../components/tabs/TourTab";
import { Flexed } from "../../Login";
import CreateLeague from "../Leagues/createleague/CreateLeague";
import { Curved } from "../Leagues/Leagues";
import JoinTournaments from "./jointournament/JoinTournaments";
import TourPaths from "./paths/TourPaths";
import { toast } from "react-toast";
import { useNavigate } from "react-router-dom";
import AllTournaments from "./paths/AllTournaments";
import LeagueRules from "../Leagues/createleague/LeagueRules";
import Logo from "../../../components/Logo/Logo";

const Wrapper = styled.div`
  position: relative;
  padding: 0rem;
  width: -webkit-fill-available;

  @media (min-width: 768px) {
    display: grid;
    place-items: center;
    padding: 2rem 2rem 0 2rem;
  }
  h2 {
    color: white;
  }

  .footing {
    max-width: 1000px;
    overflow-x: hidden;
    min-width: 380px;

    @media (max-width: 768px) {
      padding: 1vh 0;
    }
  }

  .b-holding {
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 4%;
    left: 50%;
    transform: translate(-50%, 50%);
    background: #1c2127;
    width: 100%;

    @media (max-width: 768px) {
      bottom: 5%;
    }
  }
`;

const AppWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
`;

const TourWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const ExCurved = styled.div`
  position: relative;
  display: flex;
  background: hsl(224deg 29.41% 10% / 55%);
  justify-content: center;
  padding: 1rem 1.5rem 2rem 1.5rem;
  width: 550px;

  @media (max-width: 768px) {
    margin: 0 0.5rem;
    width: unset;
  }

  .hold {
    position: relative;
    margin: 1rem 1rem 0 1rem;
    width: 100%;
  }
`;

function Tournaments() {
  const navigate = useNavigate();
  const [league, setLeague] = useState(true);
  const [rules, setRules] = useState(false);
  const [country, setCountry] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [createData, setCreateData] = useState(null);
  const token = localStorage.getItem("PRivalsToken");

  useEffect(() => {
    if (!token) {
      toast.error("Access Denied, Kindly Login to get access");
      navigate("/");
    }
  }, []);

  const getRules = (data) => {
    setRules(data);
  };

  const getCurrent = (data) => {
    setCurrentTab(data);
  };

  const getCountry = (data) => {
    setCountry(data);
  };

  const getData = (data) => {
    setCreateData(data);
  };

  return (
    <Wrapper>
      <div className="inner-wrapper">
        <Logo />
        <Spacer height="0.2rem" />
        {!rules ? (
          <Curved>
            <div className="hold">
              <Spacer height="0.1rem" />
              <TourWrapper>
                <TourTab
                  current={currentTab}
                  fTitle="Tournaments"
                  sTitle="Join Tournament"
                  tTitle="Create Tournament"
                  fContent={<AllTournaments getCurrent={getCurrent} />}
                  sContent={
                    <JoinTournaments
                      fTitle="Tournament Code"
                      sTitle="Search Tournament"
                    />
                  }
                  tContent={
                    <CreateLeague
                      getRules={getRules}
                      type="Tournament"
                      getCurrent={getCurrent}
                      getCountry={getCountry}
                      getData={getData}
                    />
                  }
                  league={league}
                />
              </TourWrapper>
            </div>
          </Curved>
        ) : (
          <ExCurved>
            <div className="hold">
              <LeagueRules
                type="Tournament"
                country={country}
                getRules={getRules}
                data={createData}
              />
            </div>
          </ExCurved>
        )}
        <Spacer height="10rem" />
      </div>
      <div className="b-holding">
        <div className="footing">
          <Selectors />
          {/* <Flexed>
            <FaFacebookF className="grey" />
            <FaInstagram className="grey" />
            <BsTwitter className="grey" />
            <FaYoutube className="grey" />
          </Flexed> */}
        </div>
      </div>
    </Wrapper>
  );
}

export default Tournaments;
