import React from "react";
import styled from "styled-components";
import { AppColors } from "../../../../config/AppColors";

const Wrapper = styled.div`
  position: relative;
  background: rgb(193 193 193 / 15%);
  padding: 0.5rem 1rem;
  height: 1.12rem;

  @media (max-width: 768px) {
    height: 1.1rem;
  }

  .match-left {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5%;
    color: ${AppColors.WHITE};
  }

  .match-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5%;
    color: ${AppColors.WHITE};
  }

  .table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${AppColors.WHITE};
    padding-left: 0.5rem;
    font-size: 0.9rem;
    @media (max-width: 768px) {
      font-size: 0.7rem;
      font-weight: 500;
    }
  }
`;

function MatchUps({ text, icon, type, position }) {
  return (
    <Wrapper
      style={{
        width:
          type === "match"
            ? "27%"
            : type === "team"
            ? "78%"
            : type === "table"
            ? "18.5%"
            : "60%",
      }}
    >
      {type === "match" ? (
        position === "home" ? (
          <div className="match-left">
            ({text}){icon}
          </div>
        ) : (
          <div className="match-right">
            {icon}({text})
          </div>
        )
      ) : type === "team" ? (
        <div className="table">
          ({text}){icon}
        </div>
      ) : (
        <div className="table">
          {text}
          {icon}
        </div>
      )}
    </Wrapper>
  );
}

export default MatchUps;
