import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import PinkButton from "../components/buttons/PinkButton";
import Form, { WrapInput } from "../components/forms/Form";
import DarkInput from "../components/Inputs/DarkInput";
import Spacer from "../components/spacer/Spacer";
import { AppColors } from "../config/AppColors";
import { getToken } from "../utils";
import { toast } from "react-toast";
import api from "../api/api";
import { FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 2rem;
  background: hsl(224deg 29.41% 10% / 55%);
  h4 {
    color: ${AppColors.WHITE};
    font-size: 1rem;
    padding: 0 2.5rem;
  }
`;
const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 2.5rem;

  &:hover {
    background: rgb(160 159 159 / 5%);
  }

  p {
    color: ${AppColors.WHITE};
  }
  svg {
    fill: ${AppColors.WHITE};
  }

  input[type="radio"] {
    display: grid;
    place-content: center;
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    color: red;
    width: 1.5em;
    height: 1.5em;
    border: 0.15em solid ${AppColors.DarkGrey};
    cursor: pointer;
    border-radius: 50%;
    transform: translateY(-0.075em);
  }

  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${AppColors.WHITE};
    cursor: pointer;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }
  input[type="radio"]:checked {
    background-color: ${AppColors.Pinkish};
    border: none;
  }
`;

const Header = styled.p`
  position: relative;
  background: rgb(193 193 193 / 15%);
  color: ${AppColors.WHITE};
  font-size: 1.2rem;
  margin: 0;
  padding: 1rem 0;
  text-align: center;

  .back {
    position: absolute;
    left: 5%;
    cursor: pointer;
  }
`;
const FormWrapper = styled.form`
  position: relative;
  padding: 1rem 10%;
`;

function ChangePassword({ getOption }) {
  const navigate = useNavigate();
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const changePass = async (e) => {
    e.preventDefault();
    if (oldPass !== "" && newPass !== "" && confirmPass == newPass) {
      let payload = {
        old_password: oldPass,
        password: newPass,
        password_confirmation: confirmPass,
      };
      axios.defaults.headers.common = {
        Authorization: `Bearer ${getToken()}`,
      };
      setLoading(true);
      const res = await api.create("change/password", payload);
      if (res.status === true) {
        console.log("data", res);
        setLoading(false);
        setData(res?.data);
        toast.success("Password Changed, Kindly log into account");
        localStorage.clear();
        navigate("/");
      } else {
        setLoading(false);
        setData(null);
        console.log("submit err", res);
      }
    }
  };

  return (
    <Wrapper>
      <Header>
        {" "}
        <HiOutlineArrowNarrowLeft
          className="back"
          onClick={() => {
            getOption("security");
          }}
        />{" "}
        Change Password
      </Header>
      <FormWrapper>
        <WrapInput>
          <DarkInput
            placeholder="Old Password"
            value={oldPass}
            onChange={(e) => setOldPass(e.target.value)}
            required={true}
          />
          <FaLock />
        </WrapInput>
        <WrapInput>
          <DarkInput
            placeholder="New Password"
            value={newPass}
            onChange={(e) => setNewPass(e.target.value)}
            required={true}
          />
          <FaLock />
        </WrapInput>
        <WrapInput>
          <DarkInput
            placeholder="Confirm Password"
            value={confirmPass}
            onChange={(e) => setConfirmPass(e.target.value)}
            required={true}
          />
          <FaLock />
        </WrapInput>
        <Spacer height="1rem" />
        <PinkButton
          isloading={loading}
          float="left"
          size="half"
          onClick={changePass}
        >
          Submit
        </PinkButton>
      </FormWrapper>
    </Wrapper>
  );
}

export default ChangePassword;
